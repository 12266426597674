$size-image-product-collection: 150px;

.ms-product-collection {
    &__items {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        @include flex-width(47%);
        position: relative;
        margin-right: 6%;
        margin-bottom: 1rem;

        &:nth-child(2n) {
            margin-right: 0;
        }

        &:last-child,
        &:nth-last-child(2):nth-child(2n-1) {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(sm) {
            @include flex-width(30%);

            &,
            &:nth-child(2n) {
                margin-right: 5%;
            }

            &,
            &:last-child,
            &:nth-last-child(2):nth-child(2n-1) {
                margin-bottom: 1.5rem;
            }

            &:nth-child(3n) {
                margin-right: 0;
            }

            &:last-child,
            &:nth-last-child(2):nth-child(3n-1),
            &:nth-last-child(2):nth-child(3n-2),
            &:nth-last-child(3):nth-child(3n-2) {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            @include flex-width(15%);

            &,
            &:nth-child(2n),
            &:nth-child(3n) {
                margin-right: 2%;
            }

            &,
            &:last-child,
            &:nth-last-child(2):nth-child(2n-1),
            &:nth-last-child(2):nth-child(3n-1),
            &:nth-last-child(2):nth-child(3n-2),
            &:nth-last-child(3):nth-child(3n-2) {
                margin-bottom: 2rem;
            }

            &:nth-child(6n) {
                margin-right: 0;
            }

            &:last-child,
            &:nth-last-child(2):nth-child(6n-1),
            &:nth-last-child(2):nth-child(6n-2),
            &:nth-last-child(2):nth-child(6n-3),
            &:nth-last-child(2):nth-child(6n-4),
            &:nth-last-child(2):nth-child(6n-5),
            &:nth-last-child(3):nth-child(6n-2),
            &:nth-last-child(3):nth-child(6n-3),
            &:nth-last-child(3):nth-child(6n-4),
            &:nth-last-child(3):nth-child(6n-5),
            &:nth-last-child(4):nth-child(6n-3),
            &:nth-last-child(4):nth-child(6n-4),
            &:nth-last-child(4):nth-child(6n-5),
            &:nth-last-child(5):nth-child(6n-4),
            &:nth-last-child(5):nth-child(6n-5),
            &:nth-last-child(6):nth-child(6n-5) {
                margin-bottom: 0;
            }
        }
    }

    &__brand {
        @extend %text;
        @extend %text-small;
        @include truncation-line(1);
        position: absolute;
        top: calc(#{$size-image-product-collection} + 0.5rem);
        text-transform: uppercase;

        + .msc-product .msc-product__image {
            margin-bottom: 2rem;
        }
    }

    .msc-product {
        display: block;

        &__image {
            margin-bottom: 0.5rem;

            img {
                width: $size-image-product-collection;
                height: $size-image-product-collection;
                object-fit: contain;
            }

            .msc-empty_image {
                @include image-placeholder($size-image-product-collection);
            }
        }

        &__title {
            @extend %text;
            @extend %text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
        }

        &__details {
            display: none;
        }
    }
}
