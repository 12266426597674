// Navigation Link
$padding-link-navigation__mobile-down: 0.35rem 0;
$margin-right-link-navigation__desktop-up: 1.5rem;

%link-navigation {
    @extend %link;
}

// Navigation Back Icon
$icon-navigation-back: $asset-icon-arrow-left;
$width-icon-navigation-back: 26px;
$height-icon-navigation-back: 17px;

%icon-navigation-back {
    @include icon-image(
        $icon-navigation-back,
        $width: $width-icon-navigation-back,
        $height: $height-icon-navigation-back
    );
}

// Navigation Dropdown
$z-index-container-navigation-dropdown__desktop-up: 50;
$padding-container-navigation-dropdown__desktop-up: 0.5rem 0;
$margin-top-container-navigation-dropdown__desktop-up: 0.5rem;
$color-background-container-navigation-dropdown__desktop-up: $color-background-accent;

// Navigation Dropdown Heading
$margin-bottom-heading-navigation-dropdown__mobile-down: 0.25rem;
$margin-left-heading-navigation-dropdown__mobile-down: 0.75rem;

// Navigation Dropdown Link
$padding-link-navigation-dropdown: 0.25rem 1rem;
$padding-link-navigation-dropdown__mobile: 0.35rem 0 0.35rem 2.35rem;

%link-navigation-dropdown {
    @extend %link-muted;
}
