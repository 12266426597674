.ms-checkout-billing-address {
    &__heading {
        @extend %heading-h6;
        @extend %text-small;
        @extend %text-muted;
        margin-bottom: 0.5rem;
    }

    &__shipping-address-label {
        display: flex;
    }

    &__input-checkbox {
        margin-top: 3px;
    }

    &__shipping-address-checkbox-text {
        @extend %text;
        @extend %text-small;
        margin-left: 0.5rem;
    }
}

.billing-address-section {
    .msc-address-form {
        justify-content: space-between;

        &__item {
            &-name {
                margin-right: 1rem;
                width: 50%;
            }

            &-street {
                margin-right: 0;
                width: calc(50% - 1rem);
            }

            &-city {
                margin-right: 1rem;
                width: 30%;
            }

            &-zipcode {
                width: calc(20% - 1rem);
            }

            &-state,
            &-threeletterisoregionname {
                width: calc(25% - 1rem);
            }
        }

        @include media-breakpoint-down(md) {
            justify-content: unset;
            flex-direction: column;

            &__item {
                &-name,
                &-street,
                &-city,
                &-zipcode,
                &-state,
                &-threeletterisoregionname{
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
    }
}
