$margin-top-invisible-anchor: 0.5rem;

.invisible-anchor {
    scroll-margin-top: calc(#{$height-container-header-sticky} + #{$margin-top-invisible-anchor});

    @include media-breakpoint-down(lg) {
        scroll-margin-top: calc(#{$height-container-header__tablet} + #{$margin-top-invisible-anchor});
    }

    @include media-breakpoint-down(xs) {
        scroll-margin-top: calc(#{$height-container-header__mobile} + #{$margin-top-invisible-anchor});
    }
}
