// Minicart
$z-index-container-minicart: 1060;
$width-container-minicart: 500px;
$padding-container-minicart: 1rem;
$border-container-minicart: 1px solid $color-border-inverse-accent;
$box-shadow-container-minicart: 0 0 6px transparentize($color-border-inverse-accent, 0.5);
$color-background-container-minicart: $color-background;

// Minicart Close Icon
$icon-minicart-close: $asset-icon-close;
$size-icon-minicart-close: 18px;
$color-icon-minicart-close: $color-text;

%icon-minicart-close {
    @include button-remove;
    @include icon-image($icon-minicart-close, $width: $size-icon-minicart-close, $color: $color-icon-minicart-close);
}

// Minicart Heading
$margin-bottom-heading-minicart: 1rem;
$text-transform-heading-minicart: uppercase;

%heading-minicart {
    @extend %heading-h6;
    @include heading-text-transform($text-transform-heading-minicart);
}

// Minicart Lines
$max-height-container-minicart-lines: 400px;
$margin-bottom-container-minicart-lines: 0.5rem;

// Minicart Line Item
$margin-container-minicart-line-item: 0.5rem 0;

// Minicart Product Image
$width-image-minicart-product: 50px;
$max-height-image-minicart-product: 50px;
$margin-right-image-minicart-product: 0.75rem;

// Minicart Product Title
$margin-right-heading-minicart-product-title: 0.75rem;
$font-weight-heading-minicart-product-title: $font-weight-bold;
$line-height-heading-minicart-product-title: 1.2;
$text-decoration-heading-minicart-product-title: none;
$text-decoration-heading-minicart-product-title--hover: none;

%heading-minicart-product-title {
    @extend %text;
    @extend %text-small;
    @include text-font-weight($font-weight-heading-minicart-product-title);
    @include text-line-height($line-height-heading-minicart-product-title);
    @include link-text-decoration($text-decoration-heading-minicart-product-title, $text-decoration-heading-minicart-product-title--hover);
}

// Minicart Product Quantity
$margin-right-text-minicart-product-quantity: 0.75rem;
$width-text-minicart-product-quantity: calc(15% - #{$margin-right-text-minicart-product-quantity});
$text-align-text-minicart-product-quantity: center;

%text-minicart-product-quantity {
    @extend %text;
    @extend %text-small;
}

// Minicart Product Price
$margin-right-text-minicart-product-price: 1rem;
$width-text-minicart-product-price: calc(20% - #{$margin-right-text-minicart-product-price});
$text-align-text-minicart-product-price: right;

%text-minicart-product-price {
    @extend %text;
    @extend %text-small;
}

// Minicart Product Variant
$content-minicart-product-variant: ',';

%text-minicart-product-variant {
    @extend %text;
    @extend %text-small;
}

// Minicart Product Remove Icon
$icon-minicart-product-remove: $asset-icon-trash;
$size-icon-minicart-product-remove: 14px;
$opacity-icon-minicart-product-remove: 0.5;
$opacity-icon-minicart-product-remove--hover: 1;

%icon-minicart-product-remove {
    @include icon-image($icon-minicart-product-remove, before, $size-icon-minicart-product-remove);
}

// Minicart Product Remove Button
%button-minicart-product-remove {
    @include button-remove;
    @extend %icon-minicart-product-remove;
    @include transition;
    opacity: $opacity-icon-minicart-product-remove;
    font-size: 0;

    @include hover-all {
        opacity: $opacity-icon-minicart-product-remove--hover;
    }
}

// Minicart Subtotal
$margin-text-minicart-subtotal: 0 (-$padding-container-minicart);
$font-weight-text-minicart-subtotal: $font-weight-bold;
$text-align-text-minicart-subtotal: right;

%text-minicart-subtotal {
    @extend %text;
    @include text-font-weight($font-weight-text-minicart-subtotal);
}

// Minicart Buttons
$margin-bottom-button-minicart: 0.75rem;

// Minicart Checkout Button
%button-minicart-checkout {
    @extend %button-primary;
}

// Minicart Cart Button
%button-minicart-cart {
    @extend %button-secondary;
}
