.ms-address-form,
.msc-address-form {
    &__item {
        margin-bottom: 0.5rem;
    }

    &__label {
        @extend %label;
    }

    &__input {
        @extend %input;
        box-sizing: border-box !important; // Override inline styling from module
    }

    &__dropdown {
        @extend %select;
    }

    &__alert {
        @extend %alert;
        @extend %alert-danger;
        display: block;
        margin-bottom: 0.5rem;
    }

    &__button-save {
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }

    &__button-cancel {
        @extend %button-secondary;
        margin-left: 1rem;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }

    &__button-save,
    &__button-cancel {
        margin-top: 1rem;
    }
}

#shipping_address {
    &addresstypevalue_container {
        width: 25%;
    }

    &name_container {
        width: 25%;
        margin: 0 1rem;
    }

    &street_container {
        width: calc(50% - 2rem);
        margin-right: 0;
    }

    &city_container {
        margin-right: 1rem;
        width: 20%;
    }

    &zipcode_container {
        width: 15%;
    }

    &threeletterisoregionname_container {
        width: calc(35% - 3rem);
    }

    &addresstypevalue_container,
    &name_container,
    &street_container,
    &city_container,
    &zipcode_container,
    &threeletterisoregionname_container {
        @include media-breakpoint-down(sm) {
            width: 100%;
            margin: 0.5rem 0;
        }
    }
}
