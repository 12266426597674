.ms-checkout-customer-account {
    @extend %text-small;

    &__account-name,
    &__account-credit-label {
        font-weight: $font-weight-bold;
    }

    &__input-label {
        @extend %label;
        display: block;
        margin-top: 1rem;
        margin-bottom: 0.25rem;
    }

    &__input-error {
        @extend %alert;
        @extend %alert-danger;
        display: block;
        margin-bottom: 0.5rem;
    }

    &__input-amount {
        @extend %input;
        width: auto;
        min-width: 150px;
        display: inline-block;
        margin-right: 1rem;
    }

    &__btn-pay {
        @extend %button-secondary;
        margin-top: 0.75rem;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }

    &__applied-line {
        margin-top: 1rem;
    }

    &__applied-label {
        font-weight: $font-weight-bold;
        margin-right: 0.25rem;
    }

    &__remove {
        @extend %link;
        @include link-text-decoration(underline);
        margin-left: 1.5rem;
    }

    &__bottom-border {
        margin-bottom: 2rem;
    }

    &__account-credit {
        &-toggle-down {
            @include icon-image(
                $asset-icon-chevron-down,
                after,
                $width: 13px,
                $height: 10px,
                $color: $color-lunar
            );
        }

        &-toggle-up {
            @include icon-image(
                $asset-icon-chevron-up,
                after,
                $width: 13px,
                $height: 10px,
                $color: $color-lunar
            );
        }

        &-toggle-down,
        &-toggle-up {
            display: inline-block;
            margin-left: 0.25rem;
        }

        &-current-order {
            &-preview-label {
                @extend %label;
                margin-top: 0.5rem;
            }
        }
    }   

    &__available-credit-section,
    &__order-total-section,
    &__excess-credit-section {
        display: flex;
        justify-content: space-between;
        @include flex-width(300px);
    }

    &__credit-details-section {
        margin-top: 1rem;
    }

    &__available-credit-section {
        margin-top: 0.25rem;
    }
}
