// Alert
$padding-alert: 0.5rem 1rem;
$border-radius-alert: 0;

%alert {
    @extend .alert;
    @extend %text;
    @extend %text-small;
    padding: $padding-alert;
    border-radius: $border-radius-alert;
    margin: 0;
    text-align: left;

    &:empty {
        display: none;
    }

    &-primary {
        @extend .alert-primary;
    }

    &-secondary {
        @extend .alert-secondary;
    }

    &-success {
        @extend .alert-success;
    }

    &-danger {
        @extend .alert-danger;
    }

    &-warning {
        @extend .alert-warning;
    }

    &-info {
        @extend .alert-info;
    }
}
