// Footer
$padding-container-footer: 3rem 0;
$padding-footer-container__mobile: 2rem 0;
$color-background-container-footer: $color-background-accent;

%width-container-footer {
    @include width-page-without-padding__breakpoint(xxl);
}

// Footer Column
$margin-bottom-container-footer-column__mobile-down: 2rem;
$margin-right-container-footer-column__desktop-up: 5rem;

// Footer Nav Column
$width-container-footer-nav-column__desktop-up: 22%;
$width-container-footer-nav-column__tablet-up: 48%;
$margin-bottom-container-footer-nav-column__mobile-down: 2rem;
$margin-right-container-footer-nav-column__tablet-up: 4%;

// Footer Legal Row
$margin-bottom-container-footer-legal-row: 2rem;
$margin-bottom-container-footer-legal-row__mobile: 1rem;

// Footer Logo
$max-width-img-footer-logo: 60px;
$max-width-img-footer-logo__mobile: 40px;

// Footer Text
$font-size-text-footer: 14px;
$text-transform-text-footer: uppercase;

%text-footer {
    @extend %text-highlight;
    @include text-font-size(14px);
    @include text-text-transform($text-transform-text-footer);
}

// Footer Heading
$font-weight-heading-footer: $font-weight-bold;

%heading-footer {
    @extend %text-footer;
    @include text-font-weight($font-weight-heading-footer);
}

// Footer Link
$margin-bottom-link-footer: 1rem;
$margin-bottom-link-footer__mobile: 0.5rem;

%link-footer {
    @extend %link;
}

// Footer Social Icon
$margin-right-icon-footer-social: 2rem;
$font-size-icon-footer-social: 18px;

$icons-footer-social: (
    facebook: $icon-facebook,
    twitter: $icon-twitter,
    linkedin: $icon-linkedin,
    instagram: $icon-instagram,
    youtube: $icon-youtube
);

@mixin icon-footer-social($icon) {
    @include icon-content($icon, $style: brands);
}

// Footer Social Link
%link-footer-social {
    @extend %link-accent;
}

// Footer Accessibility Icon
$width-icon-footer-accessibility: 25px;
$height-icon-footer-accessibility: 30px;
$color-icon-footer-accessibility: $color-text-highlight;
$icon-footer-accessibility: $asset-icon-accessibility;

%icon-footer-accessibility {
    @include icon-image(
        $icon-footer-accessibility,
        $width: $width-icon-footer-accessibility,
        $height: $height-icon-footer-accessibility,
        $color: $color-icon-footer-accessibility
    );
}

// Footer Copyright Text
$font-size-text-footer-copyright: 12px;

%text-footer-copyright {
    @extend %text-highlight;
    @include text-font-size($font-size-text-footer-copyright);
}
