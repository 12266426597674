.ms-order-template,
.msc-order-template {
    width: 100%;

    &__heading {
        margin-bottom: 1rem;

        // Div containing create a template button has an empty class
        + div[class=''] {
            margin-bottom: 2rem;
        }
    }

    &__add-template-button {
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }

    &__list-item {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__tile {
        @include center-flex(y);

        @include media-breakpoint-down(xs) {
            flex-wrap: wrap;
        }

        // Div containing order template name and # of items is classless
        > div:not([class]) {
            @include media-breakpoint-down(xs) {
                width: 100%;
                margin-bottom: 0.5rem;
            }

            @include media-breakpoint-up(sm) {
                flex: 1;
            }
        }
    }

    &__list-item-title {
        font-weight: $font-weight-bold;
    }

    &__line-count {
        @extend %text-small;

        // Divider is hardcoded into the TSX with a set color, need to remove
        + hr {
            display: none;
        }
    }

    &__image-container {
        display: none;
    }

    &__add-to-bag-button-div {
        @include media-breakpoint-up(sm) {
            margin-left: 1rem;
        }
    }

    &__add-to-bag-button {
        @extend %button-secondary;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }

    &__remove-list {
        @extend %button-minicart-product-remove;
        margin-left: 1rem;
    }

    &-action-bar {
        margin-bottom: 1rem;

        .msc-add-order-template-to-cart {
            @extend %button-primary;

            &:disabled,
            &.disabled {
                @extend %button-primary-disabled;
            }
        }

        &__rename-template-button,
        &__delete-template-button {
            @extend %button-secondary;
            margin-left: 0.75rem;

            &:disabled,
            &.disabled {
                @extend %button-secondary-disabled;
            }
        }

        .msc-alert-success {
            @extend %alert;
            @extend %alert-success;
        }

        .msc-alert-error,
        .msc-alert-danger {
            @extend %alert;
            @extend %alert-danger;
        }
    }

    &-products-status {
        margin-top: 0.5rem;
    }

    .msc-add-line-to-template__button {
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }

    .add-selected-to-bag,
    .remove-selected {
        @extend %button-secondary;
        margin-left: 0.75rem;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }

    &__content {
        overflow-x: auto;
        margin-top: 2rem;
    }

    &__table {
        @extend %text-small;
        width: 100%;
        min-width: 600px;

        thead {
            border-bottom: 1px solid $color-border;
            text-transform: uppercase;
        }

        .ms-table__heading-row,
        .ms-order-template-table-line {
            > * {
                padding: 0.25rem;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                // None of the table columns have classes
                &:first-child { // Select checkbox
                    width: 24px;
                }

                &:nth-child(2) { // Product image
                    width: 70px;

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }

            .checkbox-container {
                margin-bottom: 0;
            }
        }

        .ms-order-template-table-line {
            &:first-child > * {
                padding-top: 0.5rem;
            }

            &:last-child > * {
                padding-bottom: 0.5rem;
            }

            &__product-info-name {
                font-weight: $font-weight-bold;
            }

            .quantity__controls {
                display: none;
            }

            .quantity-input {
                @extend %input;
                width: 70px;
            }

            &__product-add-button,
            &__product-remove-button {
                @include button-remove;
                @include transition;
                opacity: 0.5;
                font-size: 0;

                @include hover-all {
                    opacity: 1;
                }
            }

            &__product-add-button {
                @include icon-image($asset-icon-cart, $width: 14px);
            }

            &__product-remove-button {
                @include icon-image($asset-icon-trash, $width: 14px);
                margin-left: 1rem;
            }
        }
    }
}

// Create order template modal
.msc-order-template-name-dialog {
    max-width: 550px !important;

    &__dialog__order-template-name-label {
        @extend %label;
    }

    &__dialog__order-template-name {
        @extend %input;
    }

    &__dialog__description {
        @extend %text;
        @extend %text-small;
        margin-top: 0.5rem;
    }

    &__dialog__create-order-template-button {
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }

    &__dialog__cancel-button {
        @extend %button-secondary;
        margin-left: 0.75rem;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }
}

// Add to order template empty modal
.msc-add-to-order-template-dialog__empty {
    max-width: 550px !important;

    &__dialog {
        &__create-order-template-image-container {
            display: none;
        }

        &__no-order-templates,
        &__no-order-templates-description {
            margin-bottom: 1rem;
        }

        &__create-order-template-button {
            @extend %button-primary;

            &:disabled,
            &.disabled {
                @extend %button-primary-disabled;
            }
        }

        &__cancel-button {
            @extend %button-secondary;
            margin-left: 0.75rem;

            &:disabled,
            &.disabled {
                @extend %button-secondary-disabled;
            }
        }
    }
}

// Add to order template existing modal
.msc-add-to-order-template-dialog__existing__dialog__order-templates {
    &-container {
        margin-top: 1rem;

        .msc-image-container {
            display: none;
        }
    }

    &-line__container {
        @include center-flex(y);
        justify-content: space-between;
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        &__create-new-line {
            margin-bottom: 2rem !important;

            &__button {
                @extend %button-primary;

                &:disabled,
                &.disabled {
                    @extend %button-primary-disabled;
                }
            }
        }
    }

    &-line__title {
        font-weight: $font-weight-bold;
    }

    &-line__count {
        @extend %text-small;
    }

    &-line__products {
        display: none;
    }

    &-line__action-button {
        @extend %button-secondary;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }
}

// Item added to order template modal
.msc-item-added-to-order-template-dialog__dialog {
    &__body,
    &__footer {
        text-align: center;
    }

    &__image-props {
        margin: auto;
        margin-bottom: 1rem;
    }

    &__description {
        margin-bottom: 1rem;
    }

    &__product-name {
        font-weight: $font-weight-bold;
    }

    &__product-dimensions {
        @extend %text-small;
    }

    &__view-order-template-button {
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }

    &__continue-shopping {
        @extend %button-secondary;
        margin-left: 0.75rem;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }
}

// Add order template to cart modal
.msc-add-order-template-to-cart__dialog {
    &__image {
        display: none;
    }

    &__back-to-order-template {
        @extend %button-primary;
        margin-top: 1rem;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }
}

// Lines added to cart modal
.msc-lines-added-to-cart-dialog__dialog {
    &__products__image-container {
        display: none;
    }

    &__description {
        margin-bottom: 1rem;
    }

    &__order-template-name {
        font-weight: $font-weight-bold;
    }

    &__order-template-items {
        @extend %text-small;
    }

    &__view-cart-button {
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }

    &__continue-shopping {
        @extend %button-secondary;
        margin-left: 0.75rem;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }
}

// Add to line template
.msc-add-line-to-template {
    &__search-form {
        @include center-flex(y);
    }

    &__search-input {
        @extend %input;

        @include media-breakpoint-up(md) {
            min-width: 300px;
        }
    }

    &__search-button {
        @include button-remove;
        @extend %icon-search-search;
        margin-left: 1rem;
    }

    &__loading__msg {
        @extend %text-small;
        @extend %text-muted;
        margin-top: 1rem;
    }

    .msc-alert-error,
    .msc-alert-danger {
        @extend %alert;
        @extend %alert-danger;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .msc-alert-success {
        @extend %alert;
        @extend %alert-success;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &__product-list {
        margin-top: 2rem;
    }

    .msc-empty_image {
        @include image-placeholder(70px);
    }

    .msc-main_image {
        width: 70px;
        max-height: 80px;

        @include media-breakpoint-up(md) {
            margin-right: 1rem;
        }
    }

    &__product {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        &,
        &__positioning-container-1 { // For some reason, all of them have -1 at the end regardless of index
            @include media-breakpoint-up(md) {
                @include center-flex(y);
            }
        }

        &__positioning-container-1 {
            @include media-breakpoint-up(md) {
                flex: 1;
            }
        }

        &__attributes {
            @include media-breakpoint-up(md) {
                margin-right: 1rem;
            }
        }

        &__id,
        &__unit-price,
        &__uom {
            @extend %text-small;
        }

        &__id {
            @extend %text-muted;
        }

        &__name {
            font-weight: $font-weight-bold;
        }

        &-config {
            margin-top: 2rem;

            &__dimensions {
                margin-top: 1rem;

                > div { // Each config selection group is classless
                    margin-bottom: 0.5rem;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    > div { // Each config label is classless
                        @extend %label;
                    }

                    .msc-dropdown__select {
                        @extend %select;
                        width: auto;
                    }
                }
            }

            .quantity-container {
                margin-top: 1rem;

                > div:not([class]) { // Quantity label is classless
                    @extend %label;
                }

                .quantity-input {
                    @extend %input;
                    width: 70px;
                }

                .quantity__controls {
                    display: none; // Hide until the rest of the site uses quantity controls
                }
            }
        }

        &__total-price {
            @extend %text-large;
            margin-top: 1rem;
            font-weight: $font-weight-bold;
        }

        &__price-container {
            @include media-breakpoint-down(sm) {
                margin-top: 0.5rem;
            }

            span { // Price element is classless
                @include media-breakpoint-down(sm) {
                    display: block;
                }
            }
        }

        &__select-button {
            @extend %button-secondary;

            &:disabled,
            &.disabled {
                @extend %button-secondary-disabled;
            }

            @include media-breakpoint-down(sm) {
                margin-top: 0.5rem;
            }

            @include media-breakpoint-up(md) {
                margin-left: 0.75rem;
            }
        }
    }

    &__add-configured-product-button {
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }
}
