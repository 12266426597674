.ms-cart-icon__flyout-container {
    border: $border-container-minicart;
    box-shadow: $box-shadow-container-minicart;
    background: $color-background;
    display: block;
    position: absolute;
    z-index: $z-index-container-minicart;
    opacity: 0;
    width: $width-container-minicart;
    margin: 0;

    &.show {
        opacity: 1;
        padding: $padding-container-minicart;
    }

    .msc-flyout__close-button {
        @extend %icon-minicart-close;
        float: right;
    }

    .ms-cart-icon__flyout-title {
        @extend %heading-minicart;
        display: block;
        margin-bottom: $margin-bottom-heading-minicart;
    }

    .ms-cart-icon__cart-lines {
        @extend %scrollbar-y;
        max-height: $max-height-container-minicart-lines;
        margin-bottom: $margin-bottom-container-minicart-lines;
    }

    .msc-cart-line {
        flex: 1;
    }

    .msc-cart-lines-item {
        @include center-flex(y);
        justify-content: space-between;
        flex: 1;
        margin: $margin-container-minicart-line-item;
    }

    .msc-cart-line__content {
        @include center-flex(y);
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
    }

    .msc-cart-line__product-image {
        margin-right: $margin-right-image-minicart-product;

        img {
            width: $width-image-minicart-product;
            max-height: $max-height-image-minicart-product;
        }

        .msc-empty_image {
            @include image-placeholder($width-image-minicart-product);
        }
    }

    .msc-cart-line__product-title {
        @extend %heading-minicart-product-title;
        flex: 1;
        margin-right: $margin-right-heading-minicart-product-title;
    }

    .msc-cart-line__quantity,
    .msc-cart-line__product-variants,
    .msc-cartline__product-unit-of-measure {
        @extend %text-minicart-product-quantity;
        @include flex-width($width-text-minicart-product-quantity);
        margin-right: $margin-right-text-minicart-product-quantity;
        text-align: $text-align-text-minicart-product-quantity;
    }

    .msc-cart-line__product-variants {
        word-break: break-all;
    }

    .msc-cart-line__product-price {
        @extend %text-minicart-product-price;
        @include flex-width($width-text-minicart-product-price);
        margin-right: $margin-right-text-minicart-product-price;
        text-align: $text-align-text-minicart-product-price;
    }

    .msc-cart-line__remove-item {
        @include center-flex(y);
        @extend %button-minicart-product-remove;
    }

    .msc-cart-line__product-variant-item,
    .msc-cart-line__bopis-method {
        @extend %text-minicart-product-variant;
    }

    .msc-cart-line__milling, // @CUSTOM
    .msc-cart-line__quantity .quantity-label,
    .msc-cart-line__product-savings-label,
    .msc-cart-line__product-savings-text,
    .msc-cart-line-item-product-discount,
    .msc-price__strikethrough {
        display: none;
    }

    .msc-cart-line__product-variants & > .msc-cart-line__product-variant-item {
        display: inline;

        &::after {
            content: $content-minicart-product-variant;
        }

        &:last-child::after {
            content: '';
        }
    }

    .msc-cart-line__product-variant-size,
    .msc-cart-line__product-variant-style,
    .msc-cart-line__product-configuration,
    .msc-cart-line__product-variant-color {
        font-size: 0;

        .name {
            @extend %text-minicart-product-variant;
        }
    }

    .ms-cart-icon__subtotal {
        @extend %text-minicart-subtotal;
        border-top: $border-container-minicart;
        margin: $margin-text-minicart-subtotal;
        padding: $padding-container-minicart;
        text-align: $text-align-text-minicart-subtotal;
    }

    .ms-cart-icon__btn-checkout,
    .ms-cart-icon__btn-gotocart {
        width: 100%;
    }

    .ms-cart-icon__btn-checkout {
        @extend %button-minicart-checkout;
        margin-bottom: $margin-bottom-button-minicart;
    }

    .ms-cart-icon__btn-gotocart {
        @extend %button-minicart-cart;
    }
}
