.ms-promo-banner {
    &.on-hold-banner {
        background-color: $color-background-bold;
        padding: 0.5rem 0;
    }

    &__carousel {
        max-width: $max-width-page;
        margin: 0 auto;
    }

    &__link {
        div {
            @extend %link;

            @include plain-hover-all {
                color: $color-mercury;
                text-decoration: underline;
            }
        }
    }

    &__close-button {
        position: absolute;
        top: 0.75rem;
        right: 0.5rem;
        @include button-remove;

        span {
            display: none;
        }

        @include icon-image(
            $asset-icon-close,
            after,
            $width: 14px,
            $color: $color-mercury
        );
    }

    &__text {
        color: $color-mercury;
        margin-right: 1rem;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: unset;
            margin-left: 1rem;
        }
    }
}
