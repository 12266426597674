.container-with-header {
    > .container-with-header__header {
        &:empty {
            display: none !important;
        }

        > .container-with-header__text {
            margin-bottom: $margin-bottom-text-container-with-header;
        }

        > .container-with-header__title {
            margin-bottom: $margin-bottom-heading-container-with-header;
        }

        &:last-child {
            > .container-with-header__text,
            > .container-with-header__title:last-child {
                margin-bottom: 0;
            }
        }
    }
}
