.ms-checkout {
    margin-top: 2rem;

    &__body {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: flex-start;
        }
    }

    &__main {
        margin-top: 3rem;

        @include media-breakpoint-up(md) {
            flex: 1;
            margin-top: 0;
            margin-right: 3rem;
        }

        @include media-breakpoint-up(xl) {
            margin-right: 5rem;
        }
    }

    &__side {
        @include media-breakpoint-up(md) {
            @include flex-width(400px);
        }

        .ms-checkout__terms-and-conditions {
            display: none;
        }
    }

    &__guided-card {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-top: 1px solid $color-border;

        &:last-child {
            border-bottom: 1px solid $color-border;
        }

        &-header {
            display: flex;
            justify-content: space-between;
        }

        &-title {
            @extend %text;
            @extend %text-small;
            @extend %text-accent;
            margin-bottom: 0;
            font-weight: $font-weight-bold;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        &-title-step {
            margin-right: 0.5rem;
        }

        &-btn-edit {
            @include button-remove;
            @extend %text-small;
            @extend %link;
            @extend %link-accent;
            @include link-text-decoration(underline);
        }

        &-body {
            margin-top: 1rem;

            @include media-breakpoint-up(md) {
                margin-left: 1.65rem;
            }
        }

        &-footer {
            margin-top: 1rem;
        }

        &-btn-save {
            @extend %button-primary;

            &:disabled,
            &.disabled {
                @extend %button-primary-disabled;
            }
        }

        &-btn-cancel {
            @extend %button-secondary;
            margin-left: 1rem;

            &:disabled,
            &.disabled {
                @extend %button-secondary-disabled;
            }
        }
    }

    &__main-control {
        margin-top: 2rem;

        @include media-breakpoint-up(md) {
            margin-top: 4rem;
        }

        .ms-checkout__btn-place-order,
        .ms-checkout__btn-keep-shopping {
            @include button-width-full__mobile-only;
        }

        .ms-checkout__btn-keep-shopping {
            margin-top: 0.75rem;

            @include media-breakpoint-up(md) {
                margin-top: 0;
                margin-left: 1rem;
            }
        }
    }

    &__email-label {
        display: none;
    }

    .msc-order-summary-wrapper,
    .msc-invoice-summary-wrapper,
    &__side-control-first {
        padding: 1rem;
        border: 1px solid $color-border;
        background-color: $color-background-accent;

        @include media-breakpoint-up(md) {
            padding: 1.5rem 2rem;
        }
    }

    .msc-order-summary-wrapper,
    .msc-invoice-summary-wrapper {
        border-bottom: 0;
    }

    .msc-order-summary__items,
    .msc-invoice-summary__items {
        margin-bottom: 0;
    }

    &__side-control-first {
        padding-top: 0;
        border-top: 0;

        .ms-checkout__btn-place-order {
            @include button-width-full;
        }

        .ms-checkout__btn-keep-shopping {
            display: none;
        }
    }

    &__line-items {
        margin-top: 2rem;

        &-header {
            display: flex;
            align-items: flex-end;
            margin-bottom: 1rem;
        }

        &-heading {
            @extend %heading-h6;
            margin-bottom: 0;
        }

        &-edit-cart-link {
            @extend %text-small;
            @extend %link;
            @include link-text-decoration(underline);
            margin-left: 1rem;
            margin-bottom: 2px;
        }

        &-delivery-group > *:not(.ms-checkout__line-items-list) {
            display: none;
        }
    }

    &__line-item {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        .msc-cart-line {
            align-items: flex-start;

            &__product-image {
                margin-right: 1rem;

                img {
                    max-height: 60px;
                }

                .msc-empty_image {
                    @include image-placeholder(50px);
                }
            }

            &__product-title {
                @extend %heading-minicart-product-title;
            }

            &__quantity {
                @extend %text-minicart-product-quantity;

                .quantity-label {
                    margin-right: 0.25rem;
                    margin-bottom: 0;

                    &::after {
                        content: ":";
                    }
                }
            }

            &__product-price {
                @extend %text-minicart-product-price;
            }
        }
    }

    &__side-control-second {
        display: none;
    }

    &__terms-and-conditions {
        margin-top: 1.5rem;
    }

    &__error-message {
        @extend %alert;
        @extend %alert-danger;
        flex: 0 0 100%;
        order: 1;
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
            order: unset;
            margin-bottom: 2rem;
        }
    }

    &__btn-place-order {
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }

    &__btn-keep-shopping {
        @extend %button-secondary;

        @include plain-hover-all {
            border-color: transparent;
        }

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }
}
