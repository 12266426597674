.ms-content-block {
    &__image,
    &__title,
    &__text,
    &__cta {
        &:empty {
            display: none;
        }
    }

    &__title {
        margin-bottom: $margin-bottom-heading-content-block;

        &:only-child {
            margin-bottom: 0;
        }
    }

    &__text a {
        @extend %link-content-block;
    }

    &__cta {
        .ms-content-block__text + & {
            margin-top: $margin-top-button-content-block;
        }
    }
}

// Content Block Extension
.ms-content-block {
    &__icon,
    &__appellation {
        &:empty {
            display: none;
        }
    }

    &__icon {
        margin-bottom: $margin-bottom-icon-content-block;
    }

    &.new-customer-info {
        margin-bottom: 1rem;
    }
}

// Content Block Layouts
.ms-content-block {
    &.layout__standard {
        &.width__full {
            width: 100%;
        }

        &.width__large {
            @include media-breakpoint-up(md) {
                width: 90%;
            }
        }

        &.width__medium {
            @include media-breakpoint-up(md) {
                width: 75%;
            }
        }

        &.width__small {
            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }

        &.width__large,
        &.width__medium,
        &.width__small {
            @include media-breakpoint-up(md) {
                margin: auto;
            }
        }
    }

    &.layout__promoblock {
        position: relative;

        .ms-content-block__details {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 1rem;

            @include media-breakpoint-up(sm) {
                padding: 2rem;
            }

            @include media-breakpoint-up(lg) {
                padding: 3rem;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                @extend %heading-inverse;
            }

            a {
                @extend %link-inverse;
            }

            p,
            span {
                @extend %text-inverse;
            }

            .ms-content-block__text {
                @extend %text-large;
            }
        }

        .ms-content-block__image img {
            width: 100%;
        }

        &.horizontalalignment {
            &__center .ms-content-block__details {
                align-items: center;
            }

            &__right .ms-content-block__details {
                align-items: flex-end;
            }
        }

        &.verticalalignment {
            &__center .ms-content-block__details {
                justify-content: center;
            }

            &__bottom .ms-content-block__details {
                justify-content: flex-end;
            }
        }

        &.gradient__top,
        &.gradient__bottom,
        &.gradient__left,
        &.gradient__right {
            > .ms-content-block__image::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        &.gradient__top > .ms-content-block__image::before {
            background: $background-container-content-block-gradient-top;
        }

        &.gradient__bottom > .ms-content-block__image::before {
            background: $background-container-content-block-gradient-bottom;
        }

        &.gradient__left > .ms-content-block__image::before {
            background: $background-container-content-block-gradient-left;
        }

        &.gradient__right > .ms-content-block__image::before {
            background: $background-container-content-block-gradient-right;
        }
    }

    &.layout__standard,
    &.layout__promoblock {
        &.horizontalalignment {
            &__center {
                img {
                    margin: auto;
                }

                .ms-content-block__details {
                    text-align: center;
                }
            }

            &__right {
                img {
                    margin-left: auto;
                }

                .ms-content-block__details {
                    text-align: right;
                }
            }
        }

        &.ctastyle {
            &__button,
            &__buttonalt {
                .ms-content-block__cta > * {
                    @include media-breakpoint-down(xxs) {
                        @include button-width-full;
                        margin-bottom: $margin-bottom-button-content-block__mobile-down;
                    }

                    @include media-breakpoint-up(xs) {
                        margin-right: $margin-right-button-content-block__tablet-up;
                    }

                    &:last-child {
                        @include media-breakpoint-down(xxs) {
                            margin-bottom: 0;
                        }

                        @include media-breakpoint-up(xs) {
                            margin-right: 0;
                        }
                    }
                }
            }

            &__button .ms-content-block__cta > * {
                @extend %button-content-block;
            }

            &__buttonalt .ms-content-block__cta > * {
                &:first-child {
                    @extend %button-primary;
                    @extend %button-primary-inverse;
                }

                &:not(:first-child) {
                    @extend %button-tertiary;
                }
            }

            &__underline .ms-content-block__cta > * {
                @extend %button-underline;
                margin: 0.5rem 1.5rem 0.5rem 0;

                &:last-child {
                    margin-right: 0;
                }
            }

            &__featured .ms-content-block__cta > * {
                @extend %button-featured;
                @include button-width-full;
                text-align: left;
                margin-bottom: 0.5rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
