%input {
    @include input;
}

%select {
    @include input;
    height: 100%;
}

%label {
    @include text(
        $color-label,
        $font-family-label,
        $font-size-label,
        $font-size-label__mobile,
        $font-weight-label,
        $font-style-label,
        $letter-spacing-label,
        $line-height-label,
        $line-height-label__mobile,
        $text-transform-label
    );
    margin-bottom: 0;
}
