// Search
$padding-y-search__tablet-down: 1rem;
$padding-container-search__tablet-down: $padding-y-search__tablet-down 2rem;
$padding-container-search__mobile-down: 1rem;
$color-background-container-search__tablet-down: $color-background-accent;

// Search Input
$width-input-search: 285px;
$height-input-search: 36px;
$padding-input-search: 0.25rem 0.5rem;
$border-color-input-search: $color-background-muted;
$border-radius-input-search: 0;
$background-color-input-search: $color-background-muted;
$font-family-input-search: $font-family-text-highlight;
$font-size-input-search: 15px;

%input-search {
    @include input(
        $padding: $padding-input-search,
        $border-radius: $border-radius-input-search,
        $color__border: $border-color-input-search,
        $color__background: $background-color-input-search,
        $font-family: $font-family-input-search,
        $font-size: $font-size-input-search,
        $font-size__mobile: $font-size-input-search
    );
    @include input-width($width-input-search);
    @include input-height($height-input-search);
    @include input-padding($padding-input-search);
}

// Search Icons
$color-icon-search: $color-text-highlight;
$margin-x-icon-search: 1rem;
$pos-right-icon-search-cancel__desktop-up: 0.5rem;
$size-icon-search-search: 22px;
$size-icon-search-search__mobile: 18px;
$size-icon-search-cancel: 14px;
$size-icon-search-cancel__mobile: $size-icon-search-cancel;
$icon-search-search: $asset-icon-search;
$icon-search-cancel: $asset-icon-close;

%icon-search {
    &-search {
        @include icon-image($icon-search-search, $width: $size-icon-search-search, $color: $color-icon-search);

        &__mobile {
            @include icon-image($icon-search-search, after, $size-icon-search-search__mobile, $color: $color-icon-search);
        }

    }

    &-cancel {
        @include icon-image($icon-search-cancel, $width: $size-icon-search-cancel, $color: $color-icon-search);

        &__mobile {
            @include icon-image($icon-search-cancel, before, $size-icon-search-cancel__mobile, $color: $color-icon-search);
        }
    }
}

// Search Results
$z-index-container-search-results: 1000;
$pos-top-container-search-results: 1px;
$pos-top-container-search-results__mobile: 68px; // Change to mobile header search's height variable
$min-width-container-search-results: 285px;
$padding-container-search-results: 0.5rem 0;
$color-background-container-search-results: $color-background;
$box-shadow-container-search-results: 0 0 5px transparentize($color-black, 0.75);

// Search Results Heading
$padding-heading-search-results: 0.5rem 1rem;
$margin-heading-search-results: 0.5rem 0;
$color-background-heading-search-results: $color-text-highlight-muted;
$color-heading-search-results: $color-text-inverse;
$letter-spacing-heading-search-results: 0.03em;
$text-transform-heading-search-results: uppercase;

// Search Results Link
$padding-link-search-results: 0.25rem 1rem;
$color-background-link-search-results--hover: $color-background-accent;

%link-search-results {
    @extend %link;

    @include hover-all {
        background-color: $color-background-link-search-results--hover;
    }
}
