.ms-product-search-result__item {
    .msc-product__image img,
    .msc_image {
        height: 150px;
        max-width: 100%;
        margin-bottom: 0.5rem;
    }

    .msc-product__title,
    .msc-product__details {
        @extend %text-small;
    }

    .msc-product__details {
        margin-top: 0.5rem;
    }

    .msc-price {
        @extend %text-muted;
        font-weight: $font-weight-bold;
        // hidden while prices are fixed
        display: none;
    }

    .msc-product__unit-of-measure,
    .msc-product__text {
        display: none;
    }
}
