.ms-search {
    @include media-breakpoint-up(xl) {
        position: relative;
    }

    &__label {
        @include center-flex(y);
    }

    &__icon {
        @include button-remove;
        @extend %icon-search-search;

        &-text {
            display: none;
        }
    }

    &__form-cancelSearch,
    &__form-cancelSearchNoSumbit {
        @include button-remove;
        @extend %icon-search-cancel;
        margin-left: $margin-x-icon-search;
    }

    &__form-submitSearch {
        display: none;
    }

    &__form {
        @include media-breakpoint-down(lg) {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            padding: $padding-container-search__tablet-down;
            background-color: $color-background-container-search__tablet-down;
        }

        @include media-breakpoint-down(xs) {
            padding: $padding-container-search__mobile-down;
        }
    }

    &__searchForm {
        @extend %icon-search-search__mobile;
        @extend %icon-search-cancel__mobile;
        @include center-flex(y);

        @include media-breakpoint-up(xl) {
            position: relative;
        }

        @include media-breakpoint-down(lg) {
            justify-content: space-between;
            width: 100%;
        }

        &::before {
            visibility: hidden;
            margin-right: $margin-x-icon-search;
        }

        &::after {
            @include media-breakpoint-up(xl) {
                position: absolute;
                right: $pos-right-icon-search-cancel__desktop-up;
            }
        }
    }

    &__form-control {
        @extend %input-search;
    }

    &__autoSuggest {
        position: absolute;
        z-index: 1000;
        top: calc(100% + #{$pos-top-container-search-results__mobile});
        left: 0;
        width: 100%;
        min-width: $min-width-container-search-results;
        padding: $padding-container-search-results;
        background-color: $color-background-container-search-results;
        box-shadow: $box-shadow-container-search-results;

        @include media-breakpoint-up(xl) {
            top: calc(100% + #{$pos-top-container-search-results});
        }

        &:empty {
            display: none;
        }

        a {
            @extend %link-search-results;
            display: block;
            padding: $padding-link-search-results;
        }

        li {
            margin: 0;
        }
    }

    .msc-autoSuggest__keywordResults-title,
    .msc-autoSuggest__productResults-title,
    .msc-autoSuggest__categoryResults-title {
        padding: $padding-heading-search-results;
        margin: $margin-heading-search-results;
        background-color: $color-background-heading-search-results;
        color: $color-heading-search-results;
        letter-spacing: $letter-spacing-heading-search-results;
        text-transform: $text-transform-heading-search-results;
    }

    .msc-autoSuggest__screen-reader {
        @include screen-reader;
    }

    // Always hide
    .msc-autoSuggest__productResults-item-img,
    .msc-autoSuggest__productResults-item-price {
        display: none;
    }

    // Hide on Desktop
    &__icon,
    &__form-cancelSearch,
    &__form-cancelSearchNoSumbit,
    &__searchForm::before {
        @include media-breakpoint-up(xl) {
            display: none;
        }
    }

    // Hide on Mobile
    &__form.bx-hide,
    &__form.bx-hide + .ms-search__autoSuggest,
    &__searchForm::after {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}
