.ms-order-confirmation {
    &__order-information > * {
        display: block;
    }

    &__btn-keep-shopping {
        @extend %button-secondary;
        margin-top: 1rem;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }

    &__group-delivery-heading {
        @extend %heading-h6;
        @extend %text-small;
        margin-bottom: 0.5rem;
    }

    &__group-delivery-total-items {
        display: none;
    }

    &__group-deliveryType-email  {
        display: none;
    }

    &__address-header {
        display: none;
    }

    &__service-total {
        margin-bottom: 1rem;
    }
}

// Shared styling between order confirmation and details pages
.ms-order-confirmation,
.ms-order-details {
    &__groups {
        margin-top: 2rem;
    }

    &__sales-lines {
        margin-top: 1rem;
    }

    &__sales-line {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__sales-lines-shipping-items-label {
        @extend %heading-h6;
        @extend %text-small;
        display: block;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
    }

    &__address {
        @extend %text-small;

        &-name {
            font-weight: $font-weight-bold;
        }

        &-name,
        &-street {
            display: block;
        }
    }

    &__order-summary {
        @extend %text-small;
        margin-top: 3rem;

        &-heading {
            @extend %heading-h6;
            @extend %text-small;
            margin-bottom: 0.5rem;
        }

        &-line-subtotal,
        &-line-shipping,
        &-line-tax-amount,
        &-line-total-amount {
            display: flex;
            justify-content: space-between;
            max-width: 200px;
            margin-bottom: 0;
        }
    }

    &__payment-methods {
        @extend %text-small;
        margin-top: 3rem;

        &-title {
            @extend %heading-h6;
            @extend %text-small;
            margin-bottom: 0.5rem;
        }

        &-line > * {
            margin-bottom: 0;
        }

        &-customer-account-amount {
            margin-left: 0.25rem;
        }
    }

    .msc-cart-line {
        @extend %text-small;
        align-items: flex-start;

        &__product-image {
            margin-right: 1rem;

            img {
                width: 70px;
                max-height: 80px;
            }

            .msc-empty_image {
                @include image-placeholder(70px);
            }
        }

        &__product-title {
            font-weight: $font-weight-bold;
        }

        &__quantity .quantity-label::after {
            content: ':';
            margin-right: 0.25rem;
        }
    }
}
