.msc-invoice-details {
    &__header {
        margin-bottom: 2rem;

        &__invoice-id {
            @extend %heading-h5;
            margin-bottom: 1rem;
        }

        &__invoice-info-container {
            margin-bottom: 1rem;

            &__order-info-container__order-info {

                &__details__order-items-count {
                    display: none;
                }

                @extend %text-small;

                @include media-breakpoint-up(md) {
                    &,
                    > * {
                        display: flex;
                    }

                    &__main-info,
                    &__details > *:nth-last-child(1) {
                        &::after {
                            content: '|';
                            margin: 0 0.75rem;
                            color: $color-text-muted;
                        }
                    }
                }
            }

            &__amount-summary {
                @extend %text-small;

                @include media-breakpoint-down(sm) {
                    &__amount-due-amount,
                    &__amount-due-text {
                        display: inline-block;
                    }
                }

                @include media-breakpoint-up(md) {
                    display: flex;

                    &__amount-due-amount::after {
                        content: '|';
                        margin: 0 0.75rem;
                        color: $color-text-muted;
                    }
                }

                &__amount-due-text::after {
                    content: ':';
                    margin-right: 0.25rem;
                }
            }
        }

        &__actions {
            &__pay-invoice {
                @extend %button-primary;

                &:disabled,
                &.disabled {
                    @extend %button-primary-disabled;
                }

                display: none;

            }
        }
    }

    &__products {
        margin-bottom: 2rem;

        &__product-line {
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }

            &__product-info-container {
                @extend %text-small;
                display: flex;

                &__product-details__name {
                    font-weight: $font-weight-bold;
                }
            }

            img,
            .msc-empty_image {
                margin-right: 1rem;
            }

            img {
                width: 70px;
                max-height: 70px;
            }

            .msc-empty_image {
                @include image-placeholder(70px);
            }

            &__total-price {
                margin-top: 0.5rem;
                margin-left: calc(70px + 1rem);
                font-weight: $font-weight-bold;
            }
        }
    }

    &__payment-details {
        @extend %text-small;

        > * {
            display: flex;
            justify-content: space-between;
            max-width: 200px;
            margin-bottom: 0;
        }
    }

    .msc-alert-error,
    .msc-alert-danger {
        @extend %alert;
        @extend %alert-danger;
        margin-top: 1rem;
    }
}

// Pay Error Modal
.msc-pay-invoice-error-dialog {
    max-width: 500px !important;

    &__error-title {
        @extend %heading-h6;
    }

    &__review-shopping-bag-button {
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }

    &__cancel-button {
        @extend %button-secondary;
        margin-left: 0.75rem;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }
}
