.ms-buybox {
    @include media-breakpoint-up(xl) {
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    &__media-gallery {
        margin-bottom: 2rem;

        @include media-breakpoint-up(xl) {
            @include flex-width(40%);
            position: absolute;
            margin-bottom: 0;
        }
    }

    &__content {
        @include media-breakpoint-up(xl) {
            @include flex-width(55%);
            margin-left: auto;
        }
    }

    // @CUSTOM
    &__product-brand {
        @extend %heading-h6;
        @extend %text-small;
        @extend %text-muted;
        margin-bottom: 0.5rem;
    }

    &__product-title {
        @extend %heading-h4;
        font-weight: $font-weight-black;
    }

    &__product-unit-of-measure {
        display: none;
    }

    &__product-description {
        margin-top: 1rem;
    }

    .msc-price {
        display: inline-block;
        margin-top: 1rem;

        &__actual,
        &__pricerange {
            @extend %text-large;
            font-weight: $font-weight-bold;
        }
    }

    &__configure {
        margin-top: 1rem;
    }

    &__dropdown {
        margin-bottom: 0.5rem;

        .msc-swatch-container__item {
            @extend %button-option;
            margin-right: 0.5rem;
            margin-top: 0.5rem;

            &:last-child {
                margin-right: 0;
            }

            &[aria-checked='true'] {
                @extend %button-option-inverse;
            }

            &__disabled {
                opacity: 0.5;
                border: 1px solid #C4C4C4;
                background: linear-gradient(to left top, transparent 47.75%, #C4C4C4 49.5%, #C4C4C4 50.5%, transparent 52.25%);
            }
        }

        .msc-alert {
            @extend %alert;
            display: block;
            margin-bottom: 0.5rem;

            &-error,
            &-danger {
                @extend %alert-danger;
            }

            &-success {
                @extend %alert-success;
            }
        }
    }

    &__action-group {
        @include center-flex(y);
        flex-wrap: wrap;
        margin-top: 1rem;
        margin-bottom: 1.5rem;

        .msc-alert {
            @extend %alert;
            width: 100%;

            &-error,
            &-danger {
                @extend %alert-danger;
                order: 10; // Make error box appear last
                margin-top: 1rem;
            }

            &-success {
                @extend %alert-success;
                display: inline-block;
            }
        }
    }

    &__dropdown-quantity-label {
        @extend %label;
    }

    &__quantity {
        margin-right: 1rem;
    }

    &__product-quantity-label {
        display: none;
    }

    .msc-dropdown__select {
        @extend %select;
        width: auto;
    }

    .msc-quantity-input {
        @extend %input;
        width: 70px;
        text-align: center;
    }

    &__inventory-info {
        margin-top: 1rem;
    }

    &__inventory-label {
        @extend %heading-h6;
        @extend %text-small;
        @extend %link-accent;
    }

    &__add-to-cart-container,
    &__add-to-order-template-container,
    &__add-to-wishlist-container {
        display: contents;
    }

    &__add-to-cart-container .msc-add-to-cart {
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
            margin: 1rem 0;
        }

        &,
        &:disabled,
        &.disabled {
            margin-right: 1rem;
        }
    }

    .msc-quantity-input,
    &__add-to-cart-container .msc-add-to-cart {
        height: 42px;
    }

    &__add-to-order-template-container {
        display: none !important; // Hide Order Template occurrences, possibly temporarily

        .msc-add-to-order-template-icon {
            @include button-remove;
            @include icon-image(
                $asset-icon-list,
                $width: 29px,
                $color: $color-text
            );
            margin-right: 1rem;
        }

        // Hide text that only pops up on hover
        div:not([class]) {
            display: none;
        }
    }

    &__add-to-wishlist-container {
        .msc-add-to-wishlist {
            @include button-remove;
            @include icon-image(
                $asset-icon-heart,
                $width: 32px,
                $height: 29px,
                $color: $color-text
            );

            &__removing {
                @include button-remove;
                @include icon-image (
                    $asset-icon-heart-solid,
                    $width: 32px,
                    $height: 29px,
                    $color: $color-text
                )
            }
        }

        .ms-wishlist-items__product-status {
            width: 100%;
            margin-top: 1rem;
        }
    }

    // @CUSTOM
    .milling-options {
        margin-top: 1rem;

        &__title {
            @extend %label;
        }

        &__options {
            margin-top: 0.5rem;

            &-form > * {
                margin-right: 0.5rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &__label {
            cursor: pointer;
        }

        &__value {
            @extend %button-option;
        }

        &__input {
            display: none;

            &:checked + .milling-options__value {
                @extend %button-option-inverse;
            }
        }
    }
}
