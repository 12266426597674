// Header
$height-container-header: 159px;
$height-container-header__tablet: 102px;
$height-container-header__mobile: 82px;
$height-container-header-sticky: 95px;
$padding-container-header: 1rem 0;
$padding-container-header__mobile: 2rem;
$padding-y-container-header-sticky: 1rem;
$color-background-container-header: $color-background;

%width-container-header {
    @include width-page-without-padding__breakpoint(xxl);
}

// Header Top Bar Column
$margin-right-container-header-top-column: 1.5rem;
$margin-right-container-header-top-column__mobile: 1rem;
$margin-top-container-header-top-column__tablet-up: 1.5rem;

// Header Top Bar Search Column
$margin-right-container-header-search__desktop-up: 2rem;

// Header Bottom Bar
$padding-top-container-header-bottom__desktop-up: 1rem;
$margin-top-container-header-bottom__desktop-up: 1rem;
$border-top-container-header-bottom__desktop-up: 1px solid $color-border-accent;

// Header Text
%text-header {
    @extend %text-highlight;
}

// Header Icon
$color-icon-header: $color-text-highlight;

$icons-header: (
    user: $asset-icon-user,
    cart: $asset-icon-cart,
    nav: $asset-icon-menu,
    close: $asset-icon-close,
    back: $asset-icon-arrow-left
);

$icons-header-width: (
    user: 24px,
    cart: 26px,
    nav: 22px,
    close: 24px,
    back: 26px
);

$icons-header-height: (
    user: map-get($icons-header-width, user),
    cart: 22px,
    nav: 15px,
    close: map-get($icons-header-width, close),
    back: 17px
);

@mixin icon-header($icon) {
    @include icon-image(
        map-get($icons-header, $icon),
        $width: map-get($icons-header-width, $icon),
        $height: map-get($icons-header-height, $icon),
        $color: $color-icon-header
    );
}

// Header Icon Text
$margin-left-text-header-icon: 1rem;

// Header Logo
$max-width-img-header-logo: 210px;
$max-width-img-header-logo__mobile: 150px;

// Header Main Nav
$margin-top-container-header-nav-main__mobile-down: 1rem;

// Header Main Nav Link
$margin-right-link-header-nav-main__desktop-up: 2rem;
$font-size-link-header-nav-main: 17px;
$font-weight-link-header-nav-main: $font-weight-bold;
$letter-spacing-link-header-nav-main: 0.07em;
$text-transform-link-header-nav-main: uppercase;

%link-header-nav-main {
    @extend %link;
    @include text-font-size($font-size-link-header-nav-main);
    @include text-font-weight($font-weight-link-header-nav-main);
    @include text-letter-spacing($letter-spacing-link-header-nav-main);
    @include text-text-transform($text-transform-link-header-nav-main);
}

// Header Sub Nav
$margin-top-container-header-nav-sub__mobile-down: 0.5rem;

// Header Sub Nav Link
$font-size-link-header-nav-sub: 14px;
$font-weight-link-header-nav-sub: $font-weight-bold;
$letter-spacing-link-header-nav-sub: 0.03em;
$text-transform-link-header-nav-sub: uppercase;

%link-header-nav-sub {
    @extend %link-muted;
    @include text-font-size($font-size-link-header-nav-sub);
    @include text-font-weight($font-weight-link-header-nav-sub);
    @include text-letter-spacing($letter-spacing-link-header-nav-sub);
    @include text-text-transform($text-transform-link-header-nav-sub);
}

// Header Nav Dropdown Heading
$font-weight-heading-header-nav-dropdown: $font-weight-bold;
$letter-spacing-heading-header-nav-dropdown: 0.07em;
$text-transform-heading-header-nav-dropdown: uppercase;

%heading-header-nav-dropdown {
    @extend %text-header;
    @include text-font-weight($font-weight-heading-header-nav-dropdown);
    @include text-letter-spacing($letter-spacing-heading-header-nav-dropdown);
    @include text-text-transform($text-transform-heading-header-nav-dropdown);
}

// Header Nav Dropdown Link
$font-size-link-header-nav-dropdown: 14px;
$font-weight-link-header-nav-dropdown: $font-weight-bold;
$letter-spacing-link-header-nav-dropdown: 0.03em;
$text-transform-link-header-nav-dropdown: uppercase;

%link-header-nav-dropdown {
    @extend %link-muted;
    @include text-font-size($font-size-link-header-nav-dropdown);
    @include text-font-weight($font-weight-link-header-nav-dropdown);
    @include text-letter-spacing($letter-spacing-link-header-nav-dropdown);
    @include text-text-transform($text-transform-link-header-nav-dropdown);
}

// Header Account Dropdown
$z-index-container-header-account-dropdown: 500;
$margin-top-container-header-account-dropdown: 0.5rem;
$color-background-header-account-dropdown: $color-background-accent;

// Header Account Dropdown Link
$padding-link-header-account-dropdown: 0.25rem 1.5rem;
$padding-top-link-header-account-dropdown: 1rem;
$padding-bottom-link-header-account-dropdown: 1rem;

%link-header-account-dropdown {
    @extend %link-header-nav-dropdown;
}

// Header Account Mobile Links
$margin-top-container-header-account-mobile-links: 1rem;

// Header Account Mobile Link
$padding-link-header-account-dropdown-mobile: 0.25rem 0;
$font-size-link-header-account-mobile: 15px;
$font-weight-link-header-account-mobile: $font-weight-bold;
$letter-spacing-link-header-account-mobile: 0.03em;
$text-transform-link-header-account-mobile: uppercase;

%link-header-account-mobile {
    @extend %link-accent;
    @include text-font-size($font-size-link-header-account-mobile);
    @include text-font-weight($font-weight-link-header-account-mobile);
    @include text-letter-spacing($letter-spacing-link-header-account-mobile);
    @include text-text-transform($text-transform-link-header-account-mobile);
}
