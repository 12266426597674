.aad {
    padding: $padding-container-aad;
}

.aad__sign-in {
    .divider,
    .create {
        display: none;
    }
}

.ms-aad-generic {
    @extend %text;
    @include center-flex;
    flex-direction: column;
    max-width: $max-width-container-aad;
    margin: auto;

    .ms-sign-up__container,
    &__container {
        width: $width-container-aad;
    }

    .ms-sign-up__verify-email-send-button,
    .ms-sign-up__verify-email-verify-button,
    .ms-sign-up__verify-email-resend-button {
        background-color: $color-background-bold !important;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    .verify,
    .divider {
        margin-top: $margin-top-general-aad;
    }

    .intro h2,
    .divider h2,
    &__heading {
        @extend %heading-h2;
        margin-bottom: $margin-bottom-general-aad;
    }

    .attrEntry,
    .entry-item {
        margin-bottom: $margin-bottom-general-aad;

        label {
            @extend %label;
        }

        input {
            @extend %input;
            margin-top: $margin-top-input-aad;
            width: $width-input-aad;
            border: 1px solid $color-input__border;

            &:focus {
                border: 1px solid $color-input__border--active;
                outline: none;
            }
        }
    }

    .password-label label {
        &::after {
            content: "\A";
            white-space: pre;
        }
    }

    #forgotPassword {
        @extend %text-small;
        @extend %link-accent;
        float: right;
    }

    #createAccount {
        @extend %link-accent;
        margin-top: $margin-top-link-aad;
        margin-left: $margin-left-container-aad-create-account;
    }

    .accountButton {
        @extend %button-primary;
        @include vfi();
        width: $width-button-aad;
        margin-bottom: $margin-bottom-general-aad;

        &:not(:disabled) {
            cursor: pointer;
        }

        &:hover,
        &:focus {
            background-color: $color-button-primary__background--hover;
            border-color: $color-button-primary__border--hover;
        }
    }

    .sendButton,
    .verifyButton,
    .editButton,
    .defaultButton,
    .sendCode,
    .verifyCode,
    .sendNewCode,
    .changeClaims,
    #continue,
    #next {
        @extend %button-primary;
        @include vfi();
        margin-bottom: $margin-bottom-general-aad;
        width: $width-button-aad;

        &:not(:disabled) {
            cursor: pointer;
        }
    }

    #cancel {
        @extend %button-secondary;
        @include vfi();
        margin-bottom: $margin-bottom-general-aad;
        width: $width-button-aad;

        &:not(:disabled) {
            cursor: pointer;
        }
    }

    .error {
        &.itemLevel {
            @extend %alert;
            @extend %alert-danger;
        }

        &.pageLevel {
            @extend %alert;
            @extend %alert-danger;
            display: none;
            margin-top: $margin-top-text-aad-message;
            margin-bottom: $margin-bottom-page-aad-error;
            padding: $padding-alert-aad;
        }

        &[aria-hidden='true'] {
            display: none;
        }
    }

    .intro p,
    .helpLink {
        display: none;
    }

    /* Override inline style inject by AAD so we need important */
    #emailVerificationControl_success_message[aria-hidden="false"],
    #emailVerificationControl_error_message[aria-hidden="false"] {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        display: block !important;
    }

    #emailVerificationControl_success_message {
        @extend %alert;
        @extend %alert-success;
        margin-bottom: $margin-bottom-general-aad;
        padding: $padding-alert-aad;
    }

    #emailVerificationControl_error_message {
        @extend %alert;
        @extend %alert-danger;
        margin-bottom: $margin-bottom-general-aad;
        padding: $padding-alert-aad;
    }
}

// Hide extra B2C header
#api > .heading {
    display: none;
}
