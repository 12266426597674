.ms-business-sign-up {
    &__container {
        max-width: 1000px;
        margin: auto;
    }

    &__form-item {
        margin-bottom: 1rem;

        &-label {
            @extend %label;
        }

        &-input,
        &-input-area,
        &-input-select {
            @extend %input;
        }

        &__item-error-text {
            @extend %alert;
            @extend %alert-danger;
            margin-bottom: 0.5rem !important;

            &:empty {
                display: none;
            }
        }

        &-FirstName {
            width: 27%;
        }

        &-LastName,
        &-CompanyEmail {
            width: calc(27% - 1rem);
        }

        &-JobTitle {
            width: calc(19% - 1rem);
            height: 40px;
        }

        &-FirstName,
        &-LastName,
        &-CompanyEmail {
            input {
                width: 100%;
            }
        }

        &-JobTitle {
            select {
                width: 100%;
            }
        }

        &-CompanyName {
            width: 45%;
        }

        &-TaxID {
            width: calc(30% - 1rem);
        }

        &-Barrelage {
            width: calc(25% - 1rem);
        }

        &-CompanyName,
        &-TaxID,
        &-Barrelage {
            input {
                width: 100%;
            }
        }

        &-CompanyAddress {
            width: 100%;
        }

        &-FirstName,
        &-LastName,
        &-CompanyEmail,
        &-CompanyName,
        &-TaxID {
            margin-right: 1rem;
        }

        &-FirstName,
        &-LastName,
        &-CompanyEmail,
        &-CompanyName,
        &-TaxID,
        &-Barrelage,
        &-JobTitle {
            @include media-breakpoint-down(md) {
                width: 100%;
                margin-right: 0;
                margin-top: 0.5rem;
                height: unset;
            }
        }
    }

    &__create-button {
        @extend %button-primary;
        margin-top: 1rem;
        justify-self: flex-start;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }

    &__cancel-button {
        @extend %button-secondary;
        margin-top: 1rem;
        margin-left: 0.75rem;
        justify-self: flex-start;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }

    &__create-button,
    &__cancel-button {
        width: 269px;

        @include media-breakpoint-down(md) {
            width: 40%;
            margin: 0 auto;
        }
    }

    &__heading {
        text-align: center;
        font-size: $font-size-heading-h4;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2.5rem;
    }
}

.msc-address {
    &-form {
        display: flex;
        flex-wrap: wrap;

        &__item {
            margin-bottom: 1rem;

            &-street {
                width: 50%;
            }

            &-city {
                width: calc(50% - 1rem);
            }

            &-state {
                width: 30%;
            }

            &-zipcode {
                width: calc(40% - 1rem);
            }

            &-threeletterisoregionname {
                width: calc(30% - 1rem);
            }

            &-phone {
                width: calc(20% - 1rem);
            }

            &-street,
            &-county,
            &-state,
            &-zipcode {
                margin-right: 1rem;
            }

            &-street,
            &-city,
            &-state,
            &-county,
            &-zipcode,
            &-threeletterisoregionname,
            &-phone {
                @include media-breakpoint-down(md) {
                    width: 100%;
                    margin-right: 0;
                    margin-top: 0.5rem;
                    height: unset;
                }
            }

            // hide phone and county provisionally
            &-phone,
            &-county {
                display:  none;
            }

        }

        &__dropdown {
            height: 40px;
        }
    }
}

.upload-manager {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;

    &__heading {
        width: 100%;
        text-align: center;
        font-size: $font-size-heading-h4;
        margin: 2.5rem 0;
    }

    &__container {
        display: grid;
        gap: 10px 50px;
        grid-template-columns: 2fr 3fr 2fr;
        margin-top: 0.5rem;

        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr;
        }
    }

    &__instructions {
        margin-top: 0.5rem;

        p {
            font-weight: $font-weight-bold;
            font-size: $font-size-text;
        }

        ul {
            margin-top: 1rem;

            li {
                font-weight: $font-weight-normal;
                font-size: $font-size-text;
            }
        }
    }

    &__target {
        text-align: center;
        display: flex;
        flex-direction: column;
        border: 2px dashed $color-tea;
        background: $color-pampas;
        margin-bottom: 0.5rem;
        transition: 0.5s;

        &.upload-box-dropping {
            opacity: 0.5;
        }

        &-icon {
            margin-top: 2.5rem;
            margin-bottom: 1rem;
            align-self: center;
            @include icon-image(
                $asset-icon-upload, 
                $width: 42px, 
                $height: 31px, 
                $color: $color-ronchi
            );
        }

        &-column {
            display: flex;
            flex-direction: column;
        }

        &-drag-and-drop,
        &-or {
            font-weight: $font-weight-bold;
        }

        &-or {
            margin: 0 5rem 0;
            color: $color-mantle;
        }

        &-button {
            @include button;
            width: 50%;
            align-self: center;
            color: $color-white;
            margin-top: 1rem;
            margin-bottom: 2.5rem;
            &:hover,
            &:focus {
                color: $color-white !important;
            }
        }

        &-formats {
            color: $color-tea;
            text-align: center;
            font-size: $font-size-text-small;
        }
    }

    &__uploads {
        &-header {
            font-size: $font-size-text-small;
            color: $color-tea;
            margin: 1rem 0;
        }

        &-entry {
            padding-bottom: 0.5rem;
            border-bottom: 1px solid $color-tea;
            display: grid;
            grid-template-columns: 6fr 1fr;
            margin-top: 1rem;
            word-break: break-all;

            &-remove {
                align-self: center;
                justify-self: center;

                @include icon-image(
                    $asset-icon-trash, 
                    $width: 20px
                );

                &:hover {
                    cursor: pointer;
                }
            }

            &-size {
                color: $color-mantle;
            }
        }

        &-progress {
            word-break: break-all;

            &-entry-status__inner {
                height: 3px;
                background-color: $color-mantle;
                transition: width 0.5s ease;
            }
        }

        &-entry-name {
            color: $color-lunar;
        }

        &-none,
        &-progress {
            margin-top: 1rem;
            color: $color-mantle;
        }

        &-none,
        &-progress,
        &-entry-name {
            font-weight: $font-weight-bold;
            font-size: $font-size-text;
        }
    }

    &__error {
        color: $color-red;

        &-container {
            grid-column: 2/3;
            word-break: break-all;

            @include media-breakpoint-down(md) {
                grid-column: unset;
            }
        }

    }
}
