%text {
    @include text;

    &-large {
        @include text-font-size(
            $font-size-text-large,
            $font-size-text-large__mobile
        );
    }

    &-small {
        @include text-font-size(
            $font-size-text-small,
            $font-size-text-small__mobile
        );
    }

    &-inverse {
        @include text-color($color-text-inverse);
    }

    &-accent {
        @include text-color($color-text-accent);
    }

    &-muted {
        @include text-color($color-text-muted);
    }
}

%text-featured {
    @include text(
        $color-text-featured,
        $font-family-text-featured,
        $font-size-text-featured,
        $font-size-text-featured__mobile,
        $font-weight-text-featured,
        $font-style-text-featured,
        $letter-spacing-text-featured,
        $line-height-text-featured,
        $line-height-text-featured__mobile,
        $text-transform-text-featured
    );

    &-inverse {
        @include text-color($color-text-featured-inverse);
    }

    &-accent {
        @include text-color($color-text-featured-accent);
    }

    &-muted {
        @include text-color($color-text-featured-muted);
    }
}

%text-highlight {
    @include text(
        $color-text-highlight,
        $font-family-text-highlight,
        $font-size-text-highlight,
        $font-size-text-highlight__mobile,
        $font-weight-text-highlight,
        $font-style-text-highlight,
        $letter-spacing-text-highlight,
        $line-height-text-highlight,
        $line-height-text-highlight__mobile,
        $text-transform-text-highlight
    );

    &-inverse {
        @include text-color($color-text-highlight-inverse);
    }

    &-accent {
        @include text-color($color-text-highlight-accent);
    }

    &-muted {
        @include text-color($color-text-highlight-muted);
    }
}

%text-appellation {
    @include text(
        $color-text-appellation,
        $font-family-text-appellation,
        $font-size-text-appellation,
        $font-size-text-appellation__mobile,
        $font-weight-text-appellation,
        $font-style-text-appellation,
        $letter-spacing-text-appellation,
        $line-height-text-appellation,
        $line-height-text-appellation__mobile,
        $text-transform-text-appellation
    );

    &-inverse {
        @include text-color($color-text-appellation-inverse);
    }

    &-accent {
        @include text-color($color-text-appellation-accent);
    }

    &-muted {
        @include text-color($color-text-appellation-muted);
    }
}
