.ms-order-details {
    &__heading {
        margin-bottom: 1rem;
    }

    &__order-information {
        @extend %text-small;
        margin-top: -1rem;

        > *:not(:last-child)::after {
            content: '|';
            margin: 0 0.5rem;
            color: $color-text-muted;
        }

        &-count {
            display: none;
        }
    }

    &__group-delivery-heading > * {
        @extend %heading-h6;
        @extend %text-small;
    }

    &__group-delivery-total-items {
        @extend %text-small;
        @extend %text-muted;
        margin-left: 0.5rem;
        display: none;
    }

    &__group-deliveryType-email {
        display: none;
    }

    &__service-total {
        margin-bottom: 1rem;
    }

    &__tracking-info {
        @extend %text-small;
        margin-top: -1rem;
        margin-bottom: 1rem;
        font-weight: $font-weight-bold;
    }

    &__tracking-link {
        font-weight: $font-weight-normal;
    }

    &__sales-line-checkbox-control {
        display: flex;
    }

    &__sales-line-checkbox {
        width: 15px;
        margin-right: 1rem;
    }

    // Both classes have been used for the link before, so
    // including both to cover all bases
    &__sales-line-btn-buy-it-again,
    &__sales-line-btn-buy-it-again-link {
        @extend %button-secondary;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        transition-property: none;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }

    &__sales-lines .ms-order-details__sales-status {
        @extend %text-small;
        @extend %text-muted;
        display: block;
        margin-top: -1rem;
        margin-bottom: 1rem;
    }

    &__sales-line-btn-buy-it-again,
    &__sales-line-btn-buy-it-again-link,
    &__sales-lines .ms-order-details__sales-status {
        margin-left: calc(70px + 1rem);
    }

    &__sales-line__selection-enabled .ms-order-details__sales-line-btn-buy-it-again,
    &__sales-line__selection-enabled .ms-order-details__sales-line-btn-buy-it-again-link,
    &__sales-line__selection-enabled + .ms-order-details__sales-status {
        margin-left: calc(70px + 2rem + 15px);
    }

    // @CUSTOM: Hide 'Buy All Items Again' service items sales lines and corresponding sales status
    &__sales-line__selection-enabled.ms-order-details__sales-line__service-item {
        &,
        + .ms-order-details__sales-status {
            display: none;
        }
    }

    &__address {
        margin-top: 3rem;

        &-header {
            @extend %heading-h6;
            @extend %text-small;
            margin-bottom: 0.5rem;
        }
    }

    &__table-view-actions {
        margin-top: 0.5rem;

        &__enable-selection,
        &__buy-selected {
            @extend %button-primary;

            &:disabled,
            &.disabled {
                @extend %button-primary-disabled;
            }
        }

        &__disable-selection {
            @extend %button-secondary;

            &:disabled,
            &.disabled {
                @extend %button-secondary-disabled;
            }
        }

        > * {
            margin-bottom: 0.75rem;

            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
                margin-right: 1rem;
            }

            @include media-breakpoint-down(xs) {
                @include button-width-full;
            }

            &:last-child {
                margin-bottom: 0;
                margin-right: 0;
            }
        }
    }
}
