.msc-promo-code {
    &-heading {
        display: none;
    }

    &__form-container {
        margin-bottom: 1rem;

        + .msc-alert-danger {
            @extend %alert;
            @extend %alert-danger;
            margin-bottom: 1rem;
        }
    }

    &__group {
        display: flex;
    }

    &__input-box {
        @extend %input;
        margin-right: 1rem;
    }

    &__apply-btn {
        @extend %button-tertiary;

        &:disabled,
        &.disabled {
            @extend %button-tertiary-disabled;
        }
    }

    &__discount {
        padding-top: 0.5rem;

        &-heading {
            @extend %text;
            @extend %text-large;
            @include text-font-weight($font-weight-bold);

            @include media-breakpoint-down(sm) {
                font-size: $font-size-text-small;
            }
        }

        &-value {
            display: none;
        }
    }

    &__line {
        &-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-bottom: 0.5rem;
        }

        &-value {
            @extend %text;
            @extend %text-large;
            @include text-font-weight($font-weight-bold);
            margin-top: -1.75rem;

            @include media-breakpoint-down(sm) {
                font-size: $font-size-text-small;
                margin-top: -1.25rem;
            }
        }

        &__btn-remove {
            @extend %link;
            @extend %link-accent;
            @include link-text-decoration(underline);
        }
    }
}
