.ms-account-customer-balance-tile {
    &__value {
        display: inline-block;
        margin: 0 0.5rem;
        font-weight: $font-weight-bold;
    }

    &__links {
        margin-top: 1rem;
    }
}
