.ms-address-detail,
.msc-address-detail {
    &__item {
        @extend %text;
        @extend %text-small;
    }

    &__item-newline,
    &__item-address-detail_Phone,
    &__item-county {
        display: block;
    }

    &__main-item-empty,
    &__item-phone-label {
        display: none;
    }

    &__item-addresstypevalue {
        @extend %text-muted;
        @include text-text-transform(uppercase);
        @include text-font-weight($font-weight-bold);
        @include text-letter-spacing(1px);

        // hide for now due to numbers appearing from imported addresses
        display: none;
    }

    &__item-name {
        @include text-font-weight($font-weight-bold);
    }

    &__item-city {
        &::after {
            content: ',';
            margin-right: 0.25rem;
        }
    }
}
