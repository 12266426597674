.ms-account-management-address {
    .msc-address-list__heading {
        margin-bottom: 1rem;
    }

    .msc-address-list__button-add {
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
