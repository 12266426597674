.ms-checkout-delivery-options {
    &__list,
    &__option-selected {
        max-width: 400px;
    }

    &__option,
    &__option-selected {
        @include center-flex(y);
        @extend %text;
        @extend %text-small;
    }

    &__input-radio {
        margin-right: 0.75rem;
    }

    &__price {
        display: flex;
        justify-content: flex-end;
        flex: 1;
    }
}
