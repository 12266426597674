.container-with-header.layout__account > .container-with-header__slots {
    @include media-breakpoint-up(lg) {
        display: flex;
        margin-top: 3rem;
    }

    // Account Pages Sidebar
    > .container-with-header:first-child {
        padding: 1.5rem;
        background-color: $color-background-inverse;

        @include media-breakpoint-up(lg) {
            @include flex-width(30%);
            padding: 5rem 3rem;
        }

        > .container-with-header__slots > .list-of-links {
            @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: flex-end;
            }

            .list-of-links__link {
                @extend %text;
                @extend %text-small;
                @extend %link-inverse-accent;
                margin-right: 1.5rem;
                font-weight: $font-weight-bold;
                letter-spacing: 1px;
                line-height: 2;
                text-transform: uppercase;

                @include media-breakpoint-up(lg) {
                    @include center-flex(y);
                    position: relative;
                    margin-right: 0;
                    margin-bottom: 0.5rem;
                    line-height: 2.5;

                    &::before {
                        position: absolute;
                        right: calc(100% + #{$margin-right-icon-account-section});
                        width: $width-icon-account-section !important;
                    }

                    &[aria-label='Overview'] {
                        @include icon-account-section(profile);
                    }

                    &[aria-label='Addresses'] {
                        @include icon-account-section(address);
                    }

                    &[aria-label='Order History'] {
                        @include icon-account-section(orderhistory);
                    }

                    &[aria-label='My Invoices'] {
                        @include icon-account-section(invoice);
                    }

                    &[aria-label='Account Balance'] {
                        @include icon-account-section(accountbalance);
                    }

                    &[aria-label='Organization Users'] {
                        @include icon-account-section(organization);
                    }

                    &[aria-label='Favorite Items'] {
                        @include icon-account-section(wishlist);
                    }

                    &[aria-label='My Lists'] {
                        @include icon-account-section(ordertemplate);
                    }
                }

                &:last-child {
                    margin-right: 0;
                    margin-bottom: 0;
                }

                &.active {
                    @extend %link-inverse;

                    @include media-breakpoint-up(lg) {
                        &::before {
                            background-color: $color-icon-account-section--active;
                        }
                    }
                }
            }
        }
    }

    // Account Pages Main Content
    > .container-with-header:nth-child(2) {
        padding: 1.5rem;

        @include media-breakpoint-up(lg) {
            flex: 1;
            max-width: 1000px;
            padding: 0;
            margin-left: 3rem;
            margin-bottom: 3rem;
        }

        // Content Container
        > .container-with-header__slots > .container-with-header > .container-with-header__slots {
            @include media-breakpoint-up(lg) {
                display: flex;
            }

            // Content Column
            > .container-with-header {
                margin-bottom: 3rem;

                @include media-breakpoint-up(lg) {
                    margin-right: 10%;
                    margin-bottom: 0;
                }

                &:first-child {
                    @include media-breakpoint-up(lg) {
                        @include flex-width(35%);
                    }
                }

                &:last-child {
                    margin-right: 0;
                    margin-bottom: 0;

                    @include media-breakpoint-up(lg) {
                        flex: 1
                    }
                }

                [class$='__heading'] {
                    @include center-flex(y);
                    @extend %text-small;
                    @extend %text-muted;
                    position: relative;
                    margin-bottom: 1rem;

                    &::before {
                        position: absolute;
                        right: calc(100% + #{$margin-right-icon-account-section-heading});
                        width: $width-icon-account-section !important;
                    }
                }

                [class$='link'] {
                    @include plain-hover-all {
                        text-decoration: underline;
                    }
                }

                > .container-with-header__slots {
                    // Indentation to make room for tile heading icons
                    margin-left: calc(#{$width-icon-account-section} + #{$margin-right-icon-account-section-heading});

                    // Each tile block in columns
                    > * {
                        @extend %text-small;
                        margin-bottom: 3rem;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    // Profile tile styling overrides
                    .ms-account-profile-wrapper {
                        .ms-account-loyalty-tile__heading {
                            @include icon-account-section(profile);
                        }
                    }

                    // Addresses tile styling overrides
                    .ms-account-address-tile .ms-account-address-tile__heading {
                        @include icon-account-section(address);
                    }

                    // Wishlist tile styling overrides
                    .ms-account-wishlist-tile .ms-account-wishlist-tile__heading {
                        @include icon-account-section(wishlist);
                    }

                    // Order Templates tile styling overrides
                    .ms-account-order-templates-tile .ms-account-order-templates-tile__heading {
                        @include icon-account-section(ordertemplate);
                    }

                    // Account Balance tile styling overrides
                    .ms-account-customer-balance-tile .ms-account-customer-balance-tile__heading {
                        @include icon-account-section(accountbalance);
                    }

                    // Order History tile styling overrides
                    .ms-order-history {
                        &__header {
                            margin-bottom: 0;
                        }

                        &__heading {
                            @include icon-account-section(orderhistory);
                        }

                        &__sales-order-list {
                            margin-top: 0;
                        }

                        &__order-count,
                        &__ms-order-history-filter {
                            display: none;
                        }

                        &__btn-keep-shopping {
                            @include button-remove;
                            @extend %text;
                            @extend %text-small;
                            @extend %link;

                            @include plain-hover-all {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        // Breadcrumb styling overrides in My Account
        .ms-breadcrumb {
            margin-bottom: 2rem;
        }

        // Mobile My Account tables
        .ms-table__row-links-minified {
            display: flex;
            align-items: flex-end;
            flex-direction: column;

            .ms-table__row-links-toggle {
                @include button-remove;
                @include center-flex(y);

                &::after {
                    @extend %icon-account-dropdown;
                    @extend %icon-account-dropdown-expand;
                }
            }

            .ms-table__row-links {
                display: flex;
                align-items: flex-end;
                margin-top: 0.5rem;
            }
        }

        // My Account action links
        .ms-table__row-links > * {
            @include button-remove;
            @extend %link;
            @include link-text-decoration(underline);
            text-transform: lowercase;
            margin-right: 0.5rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
