%button {
    @include button;

    &-disabled {
        @include button-disable;
        @include button-color(
            $color-button-disabled__border,
            $color-button-disabled__background,
            $color-button-disabled__text,
            $color-button-disabled__border,
            $color-button-disabled__background,
            $color-button-disabled__text
        );
    }

    &-inverse {
        @include button-color(
            $color-button-inverse__border,
            $color-button-inverse__background,
            $color-button-inverse__text,
            $color-button-inverse__border,
            $color-button-inverse__background,
            $color-button-inverse__text
        );

        &-disabled {
            @include button-disable;
            @include button-color(
                $color-button-inverse-disabled__border,
                $color-button-inverse-disabled__background,
                $color-button-inverse-disabled__text,
                $color-button-inverse-disabled__border,
                $color-button-inverse-disabled__background,
                $color-button-inverse-disabled__text
            );
        }
    }
}

%button-featured {
    @include button(
        $padding-button-featured,
        $border-width-button-featured,
        $border-style-button-featured,
        $border-radius-button-featured,
        $color-button-featured__border,
        $color-button-featured__background,
        $color-button-featured__text,
        $color-button-featured__border--hover,
        $color-button-featured__background--hover,
        $color-button-featured__text--hover,
        $font-family-button-featured,
        $font-size-button-featured,
        $font-size-button-featured__mobile,
        $font-weight-button-featured,
        $font-style-button-featured,
        $letter-spacing-button-featured,
        $line-height-button-featured,
        $line-height-button-featured__mobile,
        $text-transform-button-featured,
        $text-decoration-button-featured,
        $text-decoration-button-featured--hover
    );

    &-disabled {
        @include button-disable;
        @include button-color(
            $color-button-featured-disabled__border,
            $color-button-featured-disabled__background,
            $color-button-featured-disabled__text,
            $color-button-featured-disabled__border,
            $color-button-featured-disabled__background,
            $color-button-featured-disabled__text
        );
    }

    &-inverse {
        @include button-color(
            $color-button-featured-inverse__border,
            $color-button-featured-inverse__background,
            $color-button-featured-inverse__text,
            $color-button-featured-inverse__border,
            $color-button-featured-inverse__background,
            $color-button-featured-inverse__text
        );

        &-disabled {
            @include button-disable;
            @include button-color(
                $color-button-featured-inverse-disabled__border,
                $color-button-featured-inverse-disabled__background,
                $color-button-featured-inverse-disabled__text,
                $color-button-featured-inverse-disabled__border,
                $color-button-featured-inverse-disabled__background,
                $color-button-featured-inverse-disabled__text
            );
        }
    }
}

%button-underline {
    @include button(
        $padding-button-underline,
        $border-width-button-underline,
        $border-style-button-underline,
        $border-radius-button-underline,
        $color-button-underline__border,
        $color-button-underline__background,
        $color-button-underline__text,
        $color-button-underline__border--hover,
        $color-button-underline__background--hover,
        $color-button-underline__text--hover,
        $font-family-button-underline,
        $font-size-button-underline,
        $font-size-button-underline__mobile,
        $font-weight-button-underline,
        $font-style-button-underline,
        $letter-spacing-button-underline,
        $line-height-button-underline,
        $line-height-button-underline__mobile,
        $text-transform-button-underline,
        $text-decoration-button-underline,
        $text-decoration-button-underline--hover
    );
    @include button-underline(
        $text-decoration-thickness-button-underline,
        $text-underline-offset-button-underline,
        $color-button-underline__border
    );

    &-disabled {
        @include button-disable;
        @include button-color(
            $color-button-underline-disabled__border,
            $color-button-underline-disabled__background,
            $color-button-underline-disabled__text,
            $color-button-underline-disabled__border,
            $color-button-underline-disabled__background,
            $color-button-underline-disabled__text
        );
        @include button-underline(
            $text-decoration-thickness-button-underline,
            $text-underline-offset-button-underline,
            $color-button-underline-disabled__border
        );
    }

    &-inverse {
        @include button-color(
            $color-button-underline-inverse__border,
            $color-button-underline-inverse__background,
            $color-button-underline-inverse__text,
            $color-button-underline-inverse__border,
            $color-button-underline-inverse__background,
            $color-button-underline-inverse__text
        );
        @include button-underline(
            $text-decoration-thickness-button-underline,
            $text-underline-offset-button-underline,
            $color-button-underline-inverse__border
        );

        &-disabled {
            @include button-disable;
            @include button-color(
                $color-button-underline-inverse-disabled__border,
                $color-button-underline-inverse-disabled__background,
                $color-button-underline-inverse-disabled__text,
                $color-button-underline-inverse-disabled__border,
                $color-button-underline-inverse-disabled__background,
                $color-button-underline-inverse-disabled__text
            );
            @include button-underline(
                $text-decoration-thickness-button-underline,
                $text-underline-offset-button-underline,
                $color-button-underline-inverse-disabled__border
            );
        }
    }
}

%button-option {
    @include button(
        $padding-button-option,
        $border-width-button-option,
        $border-style-button-option,
        $border-radius-button-option,
        $color-button-option__border,
        $color-button-option__background,
        $color-button-option__text,
        $color-button-option__border--hover,
        $color-button-option__background--hover,
        $color-button-option__text--hover,
        $font-family-button-option,
        $font-size-button-option,
        $font-size-button-option__mobile,
        $font-weight-button-option,
        $font-style-button-option,
        $letter-spacing-button-option,
        $line-height-button-option,
        $line-height-button-option__mobile,
        $text-transform-button-option,
        $text-decoration-button-option,
        $text-decoration-button-option--hover
    );

    &-disabled {
        @include button-disable;
        @include button-color(
            $color-button-option-disabled__border,
            $color-button-option-disabled__background,
            $color-button-option-disabled__text,
            $color-button-option-disabled__border,
            $color-button-option-disabled__background,
            $color-button-option-disabled__text
        );
    }

    &-inverse {
        @include button-color(
            $color-button-option-inverse__border,
            $color-button-option-inverse__background,
            $color-button-option-inverse__text,
            $color-button-option-inverse__border,
            $color-button-option-inverse__background,
            $color-button-option-inverse__text
        );

        &-disabled {
            @include button-disable;
            @include button-color(
                $color-button-option-inverse-disabled__border,
                $color-button-option-inverse-disabled__background,
                $color-button-option-inverse-disabled__text,
                $color-button-option-inverse-disabled__border,
                $color-button-option-inverse-disabled__background,
                $color-button-option-inverse-disabled__text
            );
        }
    }
}

%button-primary {
    @include button(
        $padding-button-primary,
        $border-width-button-primary,
        $border-style-button-primary,
        $border-radius-button-primary,
        $color-button-primary__border,
        $color-button-primary__background,
        $color-button-primary__text,
        $color-button-primary__border--hover,
        $color-button-primary__background--hover,
        $color-button-primary__text--hover,
        $font-family-button-primary,
        $font-size-button-primary,
        $font-size-button-primary__mobile,
        $font-weight-button-primary,
        $font-style-button-primary,
        $letter-spacing-button-primary,
        $line-height-button-primary,
        $line-height-button-primary__mobile,
        $text-transform-button-primary,
        $text-decoration-button-primary,
        $text-decoration-button-primary--hover
    );

    &-disabled {
        @include button-disable;
        @include button-color(
            $color-button-primary-disabled__border,
            $color-button-primary-disabled__background,
            $color-button-primary-disabled__text,
            $color-button-primary-disabled__border,
            $color-button-primary-disabled__background,
            $color-button-primary-disabled__text
        );
    }

    &-inverse {
        @include button-color(
            $color-button-primary-inverse__border,
            $color-button-primary-inverse__background,
            $color-button-primary-inverse__text,
            $color-button-primary-inverse__border,
            $color-button-primary-inverse__background,
            $color-button-primary-inverse__text
        );

        &-disabled {
            @include button-disable;
            @include button-color(
                $color-button-primary-inverse-disabled__border,
                $color-button-primary-inverse-disabled__background,
                $color-button-primary-inverse-disabled__text,
                $color-button-primary-inverse-disabled__border,
                $color-button-primary-inverse-disabled__background,
                $color-button-primary-inverse-disabled__text
            );
        }
    }
}

%button-secondary {
    @include button(
        $padding-button-secondary,
        $border-width-button-secondary,
        $border-style-button-secondary,
        $border-radius-button-secondary,
        $color-button-secondary__border,
        $color-button-secondary__background,
        $color-button-secondary__text,
        $color-button-secondary__border--hover,
        $color-button-secondary__background--hover,
        $color-button-secondary__text--hover,
        $font-family-button-secondary,
        $font-size-button-secondary,
        $font-size-button-secondary__mobile,
        $font-weight-button-secondary,
        $font-style-button-secondary,
        $letter-spacing-button-secondary,
        $line-height-button-secondary,
        $line-height-button-secondary__mobile,
        $text-transform-button-secondary,
        $text-decoration-button-secondary,
        $text-decoration-button-secondary--hover
    );

    &-disabled {
        @include button-disable;
        @include button-color(
            $color-button-secondary-disabled__border,
            $color-button-secondary-disabled__background,
            $color-button-secondary-disabled__text,
            $color-button-secondary-disabled__border,
            $color-button-secondary-disabled__background,
            $color-button-secondary-disabled__text
        );
    }

    &-inverse {
        @include button-color(
            $color-button-secondary-inverse__border,
            $color-button-secondary-inverse__background,
            $color-button-secondary-inverse__text,
            $color-button-secondary-inverse__border,
            $color-button-secondary-inverse__background,
            $color-button-secondary-inverse__text
        );

        &-disabled {
            @include button-disable;
            @include button-color(
                $color-button-secondary-inverse-disabled__border,
                $color-button-secondary-inverse-disabled__background,
                $color-button-secondary-inverse-disabled__text,
                $color-button-secondary-inverse-disabled__border,
                $color-button-secondary-inverse-disabled__background,
                $color-button-secondary-inverse-disabled__text
            );
        }
    }
}

%button-tertiary {
    @include button(
        $padding-button-tertiary,
        $border-width-button-tertiary,
        $border-style-button-tertiary,
        $border-radius-button-tertiary,
        $color-button-tertiary__border,
        $color-button-tertiary__background,
        $color-button-tertiary__text,
        $color-button-tertiary__border--hover,
        $color-button-tertiary__background--hover,
        $color-button-tertiary__text--hover,
        $font-family-button-tertiary,
        $font-size-button-tertiary,
        $font-size-button-tertiary__mobile,
        $font-weight-button-tertiary,
        $font-style-button-tertiary,
        $letter-spacing-button-tertiary,
        $line-height-button-tertiary,
        $line-height-button-tertiary__mobile,
        $text-transform-button-tertiary,
        $text-decoration-button-tertiary,
        $text-decoration-button-tertiary--hover
    );

    &-disabled {
        @include button-disable;
        @include button-color(
            $color-button-tertiary-disabled__border,
            $color-button-tertiary-disabled__background,
            $color-button-tertiary-disabled__text,
            $color-button-tertiary-disabled__border,
            $color-button-tertiary-disabled__background,
            $color-button-tertiary-disabled__text
        );
    }

    &-inverse {
        @include button-color(
            $color-button-tertiary-inverse__border,
            $color-button-tertiary-inverse__background,
            $color-button-tertiary-inverse__text,
            $color-button-tertiary-inverse__border,
            $color-button-tertiary-inverse__background,
            $color-button-tertiary-inverse__text
        );

        &-disabled {
            @include button-disable;
            @include button-color(
                $color-button-tertiary-inverse-disabled__border,
                $color-button-tertiary-inverse-disabled__background,
                $color-button-tertiary-inverse-disabled__text,
                $color-button-tertiary-inverse-disabled__border,
                $color-button-tertiary-inverse-disabled__background,
                $color-button-tertiary-inverse-disabled__text
            );
        }
    }
}

.button {
    @extend %button;
}

.button-inverse {
    @extend %button-inverse;
}

.button-featured {
    @extend %button-featured;
}

.button-featured-inverse {
    @extend %button-featured-inverse;
}

.button-underline {
    @extend %button-underline;
}

.button-underline-inverse {
    @extend %button-underline-inverse;
}

.button-option {
    @extend %button-option;
}

.button-option-inverse {
    @extend %button-option-inverse;
}

.button-primary {
    @extend %button-primary;
}

.button-primary-inverse {
    @extend %button-primary-inverse;

}

.button-secondary {
    @extend %button-secondary;
}

.button-secondary-inverse {
    @extend %button-secondary-inverse;
}

.button-tertiary {
    @extend %button-tertiary;
}

.button-tertiary-inverse {
    @extend %button-tertiary-inverse;

}
