.substitute-products {
    &__heading {
        text-transform: none;
        margin-bottom: 0.5rem;
    }

    &__link {
        font-weight: $font-weight-black;
        font-size: $font-size-heading-h6;
        text-transform: uppercase;
        text-decoration: underline;
    }

    &__price {
        display: none;
    }
}
