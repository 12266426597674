// Content Sizing
$content-layouts-max: 'standard', 'headline', 'promoblocks', 'promogrid', 'icongrid', 'textgrid', 'page', 'brands', 'product';
$content-layouts-full: 'hero', 'promobanner', 'logogrid', 'category', 'account';
$content-layouts-margin-y: 'standard', 'promoblocks', 'promogrid', 'promobanner', 'icongrid', 'textgrid', 'page', 'brands', 'product';
$content-layouts-margin-bottom: 'category';
$margin-y-container-content: 5rem;
$margin-y-container-content__mobile: 3rem;

%width-container-content-max {
    @include width-page-without-padding__breakpoint(xxl);
}

%width-container-content-full {
    @extend %width-page-full;
}

%width-container-content-full__mobile-only {
    @extend %width-page-full__mobile-only;
}

%margin-y-container-content {
    margin-top: $margin-y-container-content__mobile;
    margin-bottom: $margin-y-container-content__mobile;

    @include media-breakpoint-up(xl) {
        margin-top: $margin-y-container-content;
        margin-bottom: $margin-y-container-content;
    }
}

%margin-bottom-container-content {
    margin-bottom: $margin-y-container-content__mobile;

    @include media-breakpoint-up(xl) {
        margin-bottom: $margin-y-container-content;
    }
}

// Content Header
$content-layouts-header-split: 'standard', 'promoblocks', 'promogrid', 'promobanner', 'brands';
$content-layouts-header-center: 'hero', 'headline', 'icongrid', 'textgrid', 'logogrid', 'page';
$margin-bottom-container-content-header-split: 2rem;
$margin-bottom-container-content-header-split__mobile: 1rem;
$margin-top-container-content-header-split-text__desktop-up: 0.25rem;
$margin-bottom-container-content-header-split-text__desktop-up: 0.5rem;
$margin-left-container-content-header-split-text__desktop-up: 2rem;

// Content Promo Heading
$content-layouts-heading-promo: 'hero', 'headline', 'promoblocks';
$breakpoint-content-heading-promo: xxl;
$font-size-heading-content-heading-promo: (
    h1: ($font-size-heading-promo-h1, $font-size-heading-promo-h1__mobile),
    h2: ($font-size-heading-promo-h2, $font-size-heading-promo-h2__mobile),
    h3: ($font-size-heading-promo-h3, $font-size-heading-promo-h3__mobile),
    h4: ($font-size-heading-promo-h4, $font-size-heading-promo-h4__mobile),
    h5: ($font-size-heading-promo-h5, $font-size-heading-promo-h5__mobile),
    h6: ($font-size-heading-promo-h6, $font-size-heading-promo-h6__mobile)
);
$font-weight-heading-content-heading-promo: (
    h1: $font-weight-heading-promo-h1,
    h2: $font-weight-heading-promo-h2,
    h3: $font-weight-heading-promo-h3,
    h4: $font-weight-heading-promo-h4,
    h5: $font-weight-heading-promo-h5,
    h6: $font-weight-heading-promo-h6
);

// Content Appellation Text
$content-layouts-text-appellation: 'promobanner';
$color-text-content-text-appellation: $color-text-appellation-muted;
$font-size-text-content-text-appellation: $font-size-text-appellation;
$font-size-text-content-text-appellation__mobile: $font-size-text-appellation__mobile;

// Content Layout: Promo Grid
$width-container-content-promogrid-column: 22%;
$width-container-content-promogrid-column__mobile: 47%;
$margin-bottom-container-content-promogrid-column: 2rem;
$margin-bottom-container-content-promogrid-column__mobile: 1rem;
$margin-right-container-content-promogrid-column: 4%;
$margin-right-container-content-promogrid-column__mobile: 6%;
$margin-bottom-img-content-promogrid: 0.5rem;
