//==============================================================================
// BASE COLORS
//==============================================================================
$color-white: #fff;
$color-silver: #ccc;
$color-black: #000;
$color-red: #ff0000;
$color-rainforest: #008356;

$color-pampas: #f6f5f3;
$color-mercury: #e8e8e8;
$color-tea: #c1b9ad;
$color-mantle: #a0a4a0;
$color-zorba: #999288;
$color-soya: #645e54;
$color-lunar: #363836;

$color-fire: #b94700;
$color-carmine: #ae5032;
$color-ronchi: #edc447;
$color-tacha: #ced664;
