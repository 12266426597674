// Business Organization List Edit Icon
$icon-organization-list-edit: $asset-icon-pencil;
$size-icon-organization-list-edit: 16px;
$opacity-icon-organization-list-edit: 0.5;
$opacity-icon-organization-list-edit--hover: 1;

%icon-organization-list-edit {
    @include icon-image($icon-organization-list-edit, before, $size-icon-organization-list-edit);
}

// Business Organization List Edit Button
%button-organization-list-product-edit {
    @include button-remove;
    @extend %icon-organization-list-edit;
    @include transition;
    opacity: $opacity-icon-organization-list-edit;
    font-size: 0;

    @include hover-all {
        opacity: $opacity-icon-organization-list-edit--hover;
    }
}
