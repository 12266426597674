//==============================================================================
// FONT IMPORTS
//==============================================================================
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&family=Roboto+Condensed:ital,wght@0,400;0,700;1,400;1,700&display=swap');
$font-family-path: '../../../webfonts';

//==============================================================================
// FONT FACES
//==============================================================================
@font-face {
    font-family: 'Proxima Nova';
    font-weight: 400;
    src: url('#{$font-family-path}/ProximaNovaMedium.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: 700;
    src: url('#{$font-family-path}/ProximaNovaBold.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: 900;
    src: url('#{$font-family-path}/ProximaNovaBlack.woff') format('woff');
}

//==============================================================================
// BASE FONTS
//==============================================================================
$font-family-system: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
$font-family-proximanova: 'Proxima Nova', 'Century Gothic', sans-serif;
$font-family-roboto: 'Roboto Condensed', 'Helvetica', 'Arial', sans-serif;
