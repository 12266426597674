$brand-logo-width: 140px;

// Category Layout
.container-with-header.layout__category {
    .container-with-header > .container-with-header__slots {
        @extend %width-container-content-full__mobile-only;
    }

    // Brand Rich & Brand Simple
    &.sublayoutcategory__brandrich,
    &.sublayoutcategory__brandsimple {
        .ms-content-block,
        .ms-video-player {
            display: flex;

            @include media-breakpoint-up(xxxl) {
                margin-left: -5%;
                margin-right: -5%;
            }

            &__cta {
                margin-top: 1rem;
            }
        }

        .ms-content-block__cta,
        .ms-video-player__details > div[class=''] { // Video player CTA container div has empty class
            margin-top: 1rem;
        }
    }

    // Brand Rich & Brand Simple Content Block with Brand Logo
    &.sublayoutcategory__brandrich .ms-content-block:nth-child(2),
    &.sublayoutcategory__brandsimple .ms-content-block {
        .ms-content-block__details {
            @include media-breakpoint-up(md) {
                display: grid;
                grid-template-columns: auto auto;
                gap: 0 2rem;
            }

            @include media-breakpoint-up(lg) {
                gap: 0 3rem;
            }
        }

        .ms-content-block__icon {
            margin-bottom: 2rem;

            @include media-breakpoint-up(md) {
                grid-row: 1 / span 99;
                margin-bottom: 0;
            }

            img {
                width: 100%;
                max-width: $brand-logo-width;
            }
        }
    }

    // Brand Rich & Brand Simple CTA
    &.sublayoutcategory__brandrich .ms-content-block:first-child,
    &.sublayoutcategory__brandrich .ms-video-player,
    &.sublayoutcategory__brandsimple .ms-content-block {
        .ms-content-block__cta > *,
        .ms-video-player__details__cta__link {
            margin: 0.5rem 1rem 0.5rem 0;

            &:first-child {
                @extend %button-primary;
                @extend %button-primary-inverse;
            }

            &:not(:first-child) {
                @extend %button-tertiary;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    // Brand Rich
    &.sublayoutcategory__brandrich {
        // Both Content Block Rows & Video Player
        .ms-content-block,
        .ms-video-player {
            flex-direction: column-reverse;
        }

        // Row 1 Content Block & Video Player Container
        .ms-content-block:first-child,
        .ms-video-player {
            @include media-breakpoint-up(lg) {
                flex-direction: row;
                position: relative;
            }
        }

        // Row 1 Content Block Image & Video Player Video
        .ms-content-block:first-child .ms-content-block__image,
        .ms-video-player > div:first-child {
            @include media-breakpoint-up(lg) {
                @include flex-width(55%);
                display: flex;
                position: absolute;
                height: 100%;
            }

            picture,
            .msc-videoplayer {
                @include media-breakpoint-up(lg) {
                   width: 100%;
               }
            }

            img,
            video {
                @include image-cover;
            }
        }

        // Row 1 Content Block & Video Player Details
        .ms-content-block:first-child .ms-content-block__details,
        .ms-video-player__details {
            display: initial;
            padding: 2rem;
            background-color: $color-background-neutral;

            @include media-breakpoint-up(md) {
                padding: 2rem 3rem 2.5rem;
            }

            @include media-breakpoint-up(lg) {
                @include flex-width(45%);
                margin-left: auto;
                padding: 2rem 3.5rem 7rem;
            }

            @include media-breakpoint-up(xl) {
                padding: 2.5rem 4rem 8rem;
            }

            @include media-breakpoint-up(xxl) {
                padding: 3rem 4.5rem 8rem;
            }

            .ms-content-block__appellation,
            .ms-content-block__title,
            .ms-content-block__text,
            .ms-video-player__details__title,
            .ms-video-player__details__text {
                color: $color-text-neutral;
            }
        }

        // Row 2 Content Block
        .ms-content-block:nth-child(2) {
            @include media-breakpoint-up(lg) {
                flex-direction: row-reverse;
                position: relative;
            }

            .ms-content-block__icon img {
                margin: auto;
            }

            .ms-content-block__details {
                padding: 2rem 1.5rem;

                @include media-breakpoint-up(lg) {
                    @include flex-width(60%);
                    padding: 3rem 4rem 2rem 1rem;
                }
            }

            .ms-content-block__cta > * {
                @extend %button-underline;
                margin: 0.5rem 1.5rem 0.5rem 0;

                &:last-child {
                    margin-right: 0;
                }
            }

            .ms-content-block__image {
                @include media-breakpoint-up(lg) {
                    margin-top: -5rem;
                }

                img {
                    @include image-cover;
                }
            }
        }
    }

    // Brand Simple
    &.sublayoutcategory__brandsimple .ms-content-block {
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;
        }

        &__image {
            z-index: -1;

            @include media-breakpoint-up(md) {
                margin-left: -35%;
            }

            img {
                width: 100%;
            }
        }

        &__details {
            padding: 1.5rem;
            background-color: $color-background-neutral;

            @include media-breakpoint-down(sm) {
                width: 85%;
                max-width: 400px;
                margin-top: -30%;
            }

            @include media-breakpoint-down(xs) {
                margin-top: -40%;
            }

            @include media-breakpoint-up(md) {
                @include flex-width(60%);
                padding: 1.5rem 2rem;
            }

            @include media-breakpoint-up(lg) {
                padding: 2.5rem 3rem;
            }
        }

        &__appellation,
        &__title,
        &__text {
            color: $color-text-neutral;
        }
    }
}

// Hide Brand refiner when PLP is a brand page
.category-brand {
    .ms-refine-submenu.Brand {
        display: none;
    }
}
