//==============================================================================
// CMS CONFIG VARIABLES
//==============================================================================
$brandPrimaryColor: #ffffff;
$brandSecondaryColor: #cccccc;
$backgroundDefaultColor: #ffffff;
$textDefaultColor: #333333;
$bodyFont: Arial;
$displayFont: Impact;
$borderRadius: 4;
$iconStyle: Line;
$categoryColor: #000;
$expandedCategoryColor: #000;

:root {
    --brandPrimaryColor: #{brandPrimaryColor};
    --brandSecondaryColor: #{brandSecondaryColor};
    --backgroundDefaultColor: #{$backgroundDefaultColor};
    --textDefaultColor: #{textDefaultColor};
    --bodyFont: #{bodyFont};
    --displayFont: #{displayFont};
    --borderRadius: #{borderRadius};
    --iconStyle: #{iconStyle};
    --categoryColor: #{categoryColor};
    --expandedCategoryColor: #{expandedCategoryColor};
}

body {
    background-color: var(--backgroundDefaultColor);
}

//==============================================================================
// IMPORTS
//==============================================================================
@import '00-imports/index';
@import '01-settings/index';
@import '02-tools/index';
@import '03-global/index';
@import '04-components/index';
@import '05-modules/index';
@import '06-layout/index';
