.ms-nav {
    > .ms-nav__list {
        @include media-breakpoint-up(xl) {
            display: flex;
            flex-wrap: wrap;
        }

        // Nav Top-level Link
        > .ms-nav__list__item {
            padding: $padding-link-navigation__mobile-down;

            @include media-breakpoint-up(xl) {
                position: relative;
                padding: 0;
                margin-right: $margin-right-link-navigation__desktop-up;
                margin-bottom: 0;
            }

            &:last-child {
                @include media-breakpoint-up(xl) {
                    margin-right: 0;
                }
            }
        }
    }

    // Nav Item
    .ms-nav__list__item {
        margin-bottom: 0;
    }

    // Nav Link Button
    .ms-nav__list__item__button {
        @include button-remove;
    }

    // Nav Link, All Types
    .ms-nav__list__item__button,
    .ms-nav__list__item__link,
    .ms-nav__list__item__span,
    .ms-nav__list__mobile__container__span {
        @extend %link-navigation;
        display: block;
    }

    // Nav Dropdown Heading
    .ms-nav__list__mobile__container {
        @include center-flex(y);
        margin-bottom: $margin-bottom-heading-navigation-dropdown__mobile-down;

        &__span {
            margin-left: $margin-left-heading-navigation-dropdown__mobile-down;
        }

        // Nav Dropdown Back Button
        &__button {
            @include button-remove;
            @extend %icon-navigation-back;
        }
    }

    // Nav Dropdown
    .ms-nav__deafult {
        @include media-breakpoint-up(xl) {
            position: absolute;
            z-index: $z-index-container-navigation-dropdown__desktop-up;
        }

        > .ms-nav__list {
            @include media-breakpoint-up(xl) {
                padding: $padding-container-navigation-dropdown__desktop-up;
                margin-top: $margin-top-container-navigation-dropdown__desktop-up;
                background-color: $color-background-container-navigation-dropdown__desktop-up;
            }
        }

        // Nav Dropdown Link
        .ms-nav__list__item__button,
        .ms-nav__list__item__link,
        .ms-nav__list__item__span {
            @extend %link-navigation-dropdown;
            padding: $padding-link-navigation-dropdown__mobile;

            @include media-breakpoint-up(xl) {
                padding: $padding-link-navigation-dropdown;
                white-space: nowrap;
            }
        }
    }
}
