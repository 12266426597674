.msc-cart-lines {
    &-group {
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
            padding: 0 2rem;
        }
    }

    &-group-wraper__bopis-heading {
        display: none;
    }

    &-item {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(md) {
            display: flex;
        }
    }
}
