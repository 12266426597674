.list-of-links {
    &__link {
        display: inline-block;
        margin-right: 1rem;
        margin-top: 0.9rem;
        margin-bottom: 0.9rem;
        line-height: 1.2;

        &:last-child {
            margin-right: 0;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 0;
            margin-bottom: 0;
            line-height: 2;
        }
    }
}
