.ms-account-customer-balance {
    width: 100%;

    // Using module configurable heading
    &__account-heading {
        display: none;
    }

    // Module configurable heading is classless
    > *:first-child {
        margin-bottom: 0;

        @include media-breakpoint-up(sm) {
            display: inline-block;
            margin-bottom: 2rem;
        }
    }

    &__dropdown {
        @include media-breakpoint-down(xs) {
            width: 100%;
            margin-top: 0.5rem;
            margin-bottom: 2rem;
        }

        @include media-breakpoint-up(sm) {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            float: right;
            margin-left: 1rem;
        }
        // hide request statements
        display: none !important;

        .msc-popover-inner {
            @extend %container-account-dropdown;
        }

        button {
            @include button-remove;
            display: block;
            text-align: left;

            @include media-breakpoint-up(sm) {
                margin-left: auto;
                text-align: right;
            }

            &.ms-account-customer-balance__action__requestStatement__toggleDown,
            &.ms-account-customer-balance__action__requestStatement__toggleUp {
                @include center-flex(y);

                &::after {
                    @extend %icon-account-dropdown;
                }
            }

            &.ms-account-customer-balance__action__requestStatement__toggleDown::after {
                @extend %icon-account-dropdown-expand;
            }

            &.ms-account-customer-balance__action__requestStatement__toggleUp::after {
                @extend %icon-account-dropdown-collapse;
            }
        }
    }

    &__account {
        margin-bottom: 2rem;

        @include media-breakpoint-up(sm) {
            clear: both;
        }

        &__details {
            // Account details heading is classless
            > *:first-child {
                @extend %heading-h6;
                @extend %text-muted;
                margin-bottom: 0.5rem;
            }
        }

        &-number {
            font-weight: $font-weight-bold;
        }

        // This info appears again in the next section
        &__credit {
            display: none;
        }
    }

    &__credit-summary {
        display: flex;
        flex-wrap: wrap;

        > * {
            margin-bottom: 1rem;
            margin-right: 2rem;
        }

        &-heading {
            @extend %heading-h6;
            @extend %text-muted;
            margin-bottom: 0.5rem;
        }

        &__labels {
            @extend %label;
        }

        &__values {
            @extend %text-large;
            font-weight: $font-weight-bold;
        }
    }

    .ms-account-loyalty__home-link {
        @extend %button-secondary;
        margin-top: 1rem;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }

    &__sendToEmail {
        @extend %text-small;
        @extend %text-muted;
        margin-top: -0.5rem;
        margin-bottom: 1rem;
    }

    &__requestStatement__fromDate,
    &__requestStatement__toDate {
        margin-bottom: 0.5rem;

        label {
            @extend %label;
        }

        input {
            @extend %input;
        }
    }

    &__action__submitRequest {
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }

    &__action__cancelRequest {
        @extend %button-secondary;
        margin-left: 0.75rem;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }

    &__action__requestStatement {
        display: none !important;
    }
}
