.msc-choice-summary {
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;

        &-item {
            @extend %text;
            @extend %text-small;
            padding: 0.25rem 0.5rem;
            margin-right: 0.5rem;
            background-color: $color-background-neutral;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__clear-all {
        @extend %text;
        @extend %text-small;
        @extend %link;
        @include link-text-decoration(underline);
        display: inline-block;
        margin-bottom: 2rem;
    }

    &__glyph {
        @include icon-content(
            $icon-times,
            after
        );

        &::after {
            line-height: unset;
            color: $color-text-neutral;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        @include plain-hover-all {
            color: $color-text-neutral;
        }
    }
}
