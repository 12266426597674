.msc-order-summary,
.msc-invoice-summary {
    &__heading {
        @extend %heading-h6;
    }

    &__items {
        margin-top: 1.5rem;
        margin-bottom: 2rem;

        > *[class^='msc-order-summary__line-'],
        > *[class^='msc-invoice-summary__line'] {
            display: flex;
            justify-content: space-between;
        }

        > .msc-order-summary__line-shipping {
            display: none;
        }
    }

    &__line-sub-total,
    &__line-total {
        @extend %text;
        @extend %text-large;
        @include text-font-weight($font-weight-bold);
    }

    &__line-total {
        padding-top: 1rem;
        margin-top: 1rem;
        border-top: 1px solid $color-border-inverse;
    }
}
