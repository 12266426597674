.ms-cookie-compliance {
    position: fixed;
    z-index: 1000;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $color-background-bold;
    box-shadow: 0 0 6px transparentize($color-black, 0.75);

    &__container {
        @include width-page-without-padding__breakpoint(xxl);
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
            @include center-flex(y);
            justify-content: space-between;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }

    &__text {
        @extend %text;
        @extend %text-large;
        color: $color-text-inverse;
    }

    &__accept-button {
        @extend %button-primary;
        margin-top: 0.75rem;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
            margin-left: 2rem;
        }
    }
}
