$width-image-wishlist-item: 90px;
$height-image-wishlist-item: 90px;

.ms-wishlist-items {
    width: 100%;

    &__heading {
        margin-bottom: 1rem;
    }

    &__products {
        margin-top: 1rem;
    }

    &__product {
        @extend %text-small;
        position: relative;
        min-height: $height-image-wishlist-item;
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }

        > * {
            margin-left: calc(#{$width-image-wishlist-item} + 1rem);
        }

        picture,
        &-image {
            margin-left: 0;
        }

        &-image {
            position: absolute;
            top: 0;
            left: 0;
        }

        &-link {
            @include link-text-decoration(underline);
            display: inline-flex;
            font-weight: $font-weight-bold;
        }

        &-price {
            display: none; // Hiding for the time being per request
            margin-top: 0.5rem;
        }

        // Hiding Add button to prevent user from adding favorited product to
        // cart without the corresponding milling option they had set
        &-add-button {
            display: none;
        }

        &-remove-button {
            @extend %button-secondary;
            margin-top: 0.5rem;

            &:disabled,
            &.disabled {
                @extend %button-secondary-disabled;
            }
        }

        &-status {
            margin-top: 0.5rem;

            .msc-alert {
                @extend %alert;
            }

            .msc-alert-error,
            .msc-alert-danger {
                @extend %alert-danger;
            }

            .msc-alert-success {
                @extend %alert-success;
            }
        }
    }

    .msc-empty_image {
        @include image-placeholder($width-image-wishlist-item);
    }

    .msc-main_image {
        width: $width-image-wishlist-item;
        max-height: $height-image-wishlist-item;
    }

    .msc-wishlist-dimension__label {
        margin-right: 0.25rem;
    }

    .msc-price__actual {
        @extend %text;
        font-weight: $font-weight-bold;
    }
}
