.ms-media-gallery {
    &__carousel {
        .msc-empty_image {
            @include image-placeholder(300px);
        }
    }

    &__item {
        @include media-breakpoint-up(xl) {
            margin: auto;
        }
    }

    &__thumbnails-container {
        margin-top: 2rem;

        .msc-ss-carousel__flipper {
            display: none;
        }

        .msc-ss-carousel-slide {
            display: flex;
        }

        .msc-ss-carousel-item {
            height: 60px;
            margin-right: 0.5rem;
            margin-bottom: 0;
            border: 1px solid $color-border;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }
        }

        .msc_image {
            max-height: 100%;

            &.msc-empty_image {
                @include image-placeholder(60px);
            }
        }
    }

    .msc-flipper,
    .msc-carousel__control__prev,
    .msc-carousel__control__next,
    .msc-carousel__indicators {
        display: none;
    }
}
