.ms-checkout-terms-and-conditions {
    &__container {
        @extend %text-small;
        display: flex;
        align-items: flex-start;
    }

    &__input-checkbox {
        margin-top: 0.25rem;
        margin-right: 1rem;
    }

    a {
        @include link-text-decoration(underline);
    }
}
