.pdp-attributes {
    &:empty {
        display: none;
    }

    &__attribute {
        display: flex;
        padding: 0.25rem 0;
        break-inside: avoid;
        word-break: break-word;

        &-label {
            @include flex-width(40%);
            min-width: 120px;
            margin-right: 1rem;
            font-weight: $font-weight-bold;

            @include media-breakpoint-down(md) {
                @include flex-width(50%);
            }
        }

        &-value {
            flex: 1;
        }
    }
}
