%heading {
    @include heading;
}

%heading-h1 {
    @include heading(
        $color-heading,
        $font-family-heading-h1,
        $font-size-heading-h1,
        $font-size-heading-h1__mobile,
        $font-weight-heading-h1,
        $font-style-heading-h1,
        $letter-spacing-heading-h1,
        $line-height-heading-h1,
        $line-height-heading-h1__mobile,
        $text-transform-heading-h1
    );
}

%heading-h2 {
    @include heading(
        $color-heading,
        $font-family-heading-h2,
        $font-size-heading-h2,
        $font-size-heading-h2__mobile,
        $font-weight-heading-h2,
        $font-style-heading-h2,
        $letter-spacing-heading-h2,
        $line-height-heading-h2,
        $line-height-heading-h2__mobile,
        $text-transform-heading-h2
    );
}

%heading-h3 {
    @include heading(
        $color-heading,
        $font-family-heading-h3,
        $font-size-heading-h3,
        $font-size-heading-h3__mobile,
        $font-weight-heading-h3,
        $font-style-heading-h3,
        $letter-spacing-heading-h3,
        $line-height-heading-h3,
        $line-height-heading-h3__mobile,
        $text-transform-heading-h3
    );
}

%heading-h4 {
    @include heading(
        $color-heading,
        $font-family-heading-h4,
        $font-size-heading-h4,
        $font-size-heading-h4__mobile,
        $font-weight-heading-h4,
        $font-style-heading-h4,
        $letter-spacing-heading-h4,
        $line-height-heading-h4,
        $line-height-heading-h4__mobile,
        $text-transform-heading-h4
    );
}

%heading-h5 {
    @include heading(
        $color-heading,
        $font-family-heading-h5,
        $font-size-heading-h5,
        $font-size-heading-h5__mobile,
        $font-weight-heading-h5,
        $font-style-heading-h5,
        $letter-spacing-heading-h5,
        $line-height-heading-h5,
        $line-height-heading-h5__mobile,
        $text-transform-heading-h5
    );
}

%heading-h6 {
    @include heading(
        $color-heading,
        $font-family-heading-h6,
        $font-size-heading-h6,
        $font-size-heading-h6__mobile,
        $font-weight-heading-h6,
        $font-style-heading-h6,
        $letter-spacing-heading-h6,
        $line-height-heading-h6,
        $line-height-heading-h6__mobile,
        $text-transform-heading-h6
    );
}

%heading,
%heading-h1,
%heading-h2,
%heading-h3,
%heading-h4,
%heading-h5,
%heading-h6 {
    &-inverse {
        @include heading-color($color-heading-inverse);
    }

    &-accent {
        @include heading-color($color-heading-accent);
    }

    &-muted {
        @include heading-color($color-heading-muted);
    }
}

%heading-featured {
    @include heading(
        $color-heading-featured,
        $font-family-heading-featured,
        $font-size-heading-featured,
        $font-size-heading-featured__mobile,
        $font-weight-heading-featured,
        $font-style-heading-featured,
        $letter-spacing-heading-featured,
        $line-height-heading-featured,
        $line-height-heading-featured__mobile,
        $text-transform-heading-featured
    );
}
