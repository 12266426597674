.ms-search-result-container {
    @extend %width-container-content-max;
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
        margin-top: 3rem;
    }

    &__refine-product-section {
        @include media-breakpoint-up(md) {
            display: flex;
        }
    }

    &__refiner-section {
        @include media-breakpoint-up(md) {
            @include flex-width(250px);
            margin-right: 5%;
        }
    }

    &__product-section {
        @include media-breakpoint-up(md) {
            flex: 1;
        }
    }

    .msc-button#search-result-modal {
        @extend %button-secondary;
        margin-bottom: 2rem;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }

    &__Sort-by-category {
        @include center-flex(y);
        margin-bottom: 2rem;

        label {
            @extend %heading-h6;
            @extend %text-small;
            margin-right: 1rem;
            margin-bottom: 0;
        }

        select {
            @extend %select;
            width: auto;
        }
    }

    &__Products ul {
        display: flex;
        flex-wrap: wrap;
        margin: -1.5rem;

        > li {
            padding: 1.5rem;
            margin-bottom: 0;
            @include flex-width(100% / 2);

            @include media-breakpoint-up(lg) {
                @include flex-width(100% / 3);
            }

            @include media-breakpoint-up(xxl) {
                @include flex-width(100% / 4);
            }
        }
    }

    &__pagination {
        margin-top: 2rem;

        @include media-breakpoint-up(md) {
            margin-top: 4rem;
        }
    }

    &__no-results-message > * {
        @extend %text;
        @extend %text-large;
        display: block;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &__category-nav-section,
    &__title,
    &__category-hierarchy {
        display: none;
    }
}

.ms-sort-and-filter-modal-close {
    @extend %button-secondary;
    margin-top: 1rem;

    &:disabled,
    &.disabled {
        @extend %button-secondary-disabled;
    }
}

.brand-plp {
    .ms-refine-submenu.Brand {
        display: none;
    }
}