.sales-rep {
    position: absolute;
    width: 250px;
    right: 15%;
    margin-top: -4.5rem;

    @include media-breakpoint-down(sm) {
        position: unset;
        right: unset;
        margin-top: unset;
        width: 100%;
        margin-bottom: 2rem;
        margin-left: 2rem;
        display: flex;
        flex-direction: column;
    }

    &__label {
        display: flex;
        align-items: center;
        text-align: end;
        margin-bottom: 1rem;
        justify-content: end;

        @include icon-image(
            $asset-icon-chevron-down,
            $selector: after,
            $width: 10px,
            $color: $color-lunar
        );

        &::after {
            margin-left: 0.5rem;
        }

        &:hover {
            cursor: pointer;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 0.5rem;
            text-align: unset;
            justify-content: unset;
        }
    }

    &__container {
        @include animation-fadeInUp;

        margin-top: 0.5rem;
        background-color: $color-white;
        border: 1px solid $color-mercury;
        padding: 1rem;
        box-shadow: 0 0 6px transparentize($color-black, 0.75);

        @include media-breakpoint-down(sm) {
            width: 90%;
            margin-bottom: 1rem;
        }
    }

    &__entry {
        margin-bottom: 1rem;

        &-name,
        &-email,
        &-phone {
            font-size: $font-size-text-small;
        }
    }
}
