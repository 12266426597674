.ms-order-history {
    width: 100%;

    &__header {
        display: flex;
        align-items: flex-end;
        margin-bottom: 1rem;

        @include media-breakpoint-down(xs) {
            flex-wrap: wrap;
        }
    }

    &__heading {
        margin-bottom: 0;
    }

    &__alert {
        @extend %alert;
        @extend %alert-danger;
        display: block;
        margin-bottom: 1rem;
    }

    &__order-count {
        @extend %text-small;
        @extend %text-muted;
        margin-left: 0.5rem;
        margin-bottom: 4px;
    }

    &__btn-more {
        @include button-remove;
        @extend %link;
        @include link-text-decoration(underline);
        margin-top: 0.75rem;
        color: $color-text;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0.5rem;
        }
    }

    &__ms-order-history-filter {
        @include media-breakpoint-down(xs) {
            width: 100%;
        }

        @include media-breakpoint-up(sm) {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            margin-left: 1rem;
        }

        .msc-popover-inner {
            @extend %container-account-dropdown;
        }

        button {
            @include button-remove;
            display: block;
            text-align: left;

            @include media-breakpoint-up(sm) {
                margin-left: auto;
                text-align: right;
            }

            &.ms-order-history__ms-order-history-filter__expand-filter-button {
                @include center-flex(y);

                &::after {
                    @extend %icon-account-dropdown;
                }

                &[aria-expanded='false']::after {
                    @extend %icon-account-dropdown-expand;
                }

                &[aria-expanded='true']::after {
                    @extend %icon-account-dropdown-collapse;
                }
            }
        }
    }

    &__sales-order-list {
        max-width: 600px;
        margin-top: 2rem;
    }

    &__sales-order {
        @extend %text-small;
        margin-bottom: 1rem;

        @include media-breakpoint-up(sm) {
            display: flex;
            margin-bottom: 0.5rem;
        }

        &__expand-products-button {
            display: none;
        }
    }

    &__order-information {
        display: flex;
        justify-content: flex-end;

        @include media-breakpoint-up(sm) {
            flex: 1;
            margin-right: 1rem;
        }

        > * {
            margin-right: 1rem;
        }

        &-sales-id {
            flex: 1;
            font-weight: $font-weight-bold;
        }

        &-created-date {
            @include flex-width(80px);
        }

        &-amount {
            @include flex-width(70px);
            margin-right: 0;
        }
    }

    &__btn-order-details {
        @extend %link;
        @extend %link-accent;
        @include link-text-decoration(underline);
        min-width: 130px;
    }

    &__btn-keep-shopping {
        @extend %button-secondary;
        margin-top: 0.5rem;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }

    &__order-information-channel-reference-id,
    &__groups,
    &__sales-order__expand-products-button-wrapper,
    &__order-information-channel-name,
    &__order-information-count {
        display: none;
    }
}
