// Icons
$asset-icon-menu: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1NUB');
$asset-icon-user: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1NUm');
$asset-icon-users: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1T4F');
$asset-icon-search: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1Lp3');
$asset-icon-cart: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1T4q');
$asset-icon-heart: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1NUv');
$asset-icon-heart-solid: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1LoR');
$asset-icon-trash: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1LoX');
$asset-icon-pencil: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1Lpc');
$asset-icon-close: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1NUg');
$asset-icon-plus: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1Lp0');
$asset-icon-minus: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1T4n');
$asset-icon-arrow-left: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1NUa');
$asset-icon-chevron-up: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1T4t');
$asset-icon-chevron-down: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1LoO');
$asset-icon-location: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1T4I');
$asset-icon-message: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1T4h');
$asset-icon-list: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1NUy');
$asset-icon-document: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1NUp');
$asset-icon-invoice: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1LoU');
$asset-icon-contract: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1Lp6');
$asset-icon-accessibility: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1T4w');
$asset-icon-sales-rep: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1NUj');
$asset-icon-upload: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1T4z');
$asset-icon-pallet: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1NUd');
$asset-icon-info: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1NUs');

// Icons: Product Badges
$asset-icon-organic: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1T4C');
$asset-icon-hazardous: url('https://files-us-prod.cms.commerce.dynamics.com/cms/api/vrfhxzgvnl/binary/MA1T4L');
