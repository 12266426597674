.ms-checkout-section-container {
    &__item {
        margin-bottom: 1.5rem;

        &:last-child {
            margin-bottom: 0;
        }

        &.initialized.ready {
            .ms-checkout-customer-account >
            .ms-checkout-customer-account__form-summary >
            .ms-checkout-customer-account__btn-pay,
            .ms-checkout-customer-account >
            .ms-checkout-customer-account__form-summary >
            #ms-checkout-customer-account__label,
            .ms-checkout-customer-account >
            .ms-checkout-customer-account__form-summary >
            #ms-checkout-customer-account__amount {
                display: none !important;
            }
        }
    }
}
