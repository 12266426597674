.container-with-header.layout__product {
    // Need minimum height in case right column is shorter than the absolutely
    // positioned media gallery on the left to prevent double scrollbar
    > .container-with-header__slots {
        @include media-breakpoint-up(xl) {
            min-height: 750px;
        }
    }

    .ms-breadcrumb {
        margin-bottom: 2rem;
    }

    .pdp-attributes {
        max-width: 500px;
        margin-top: 2rem;
    }

    // @CUSTOM
    .pdp-attributes-badges {
        @include media-breakpoint-up(md) {
            columns: 2;
            column-gap: 1rem;
        }

        .pdp-attributes__attribute {
            @include center-flex(y);
            flex-direction: row-reverse;
            justify-content: flex-end;

            @include media-breakpoint-down(sm) {
                margin-bottom: 0.5rem;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .pdp-attributes__attribute-label {
            @extend %heading-h6;
            @extend %text-small;
            flex: unset;
            min-width: unset;
            max-width: unset;
            margin-right: unset;
        }

        .pdp-attributes__attribute-value {
            flex: unset;
            margin-right: 0.5rem;
        }

        .pdp-attributes__attribute-value-boolean {
            display: none;
        }

        &__organic {
            .pdp-attributes__attribute-label {
                color: $color-rainforest;
            }

            .pdp-attributes__attribute-value {
                @include icon-image($asset-icon-organic, $width: 30px, $color: default);
            }
        }

        &__hazardous {
            .pdp-attributes__attribute-label {
                color: $color-text;
            }

            .pdp-attributes__attribute-value {
                @include icon-image($asset-icon-hazardous, $width: 24px, $color: default);
            }
        }
    }

    // @CUSTOM
    .pdp-attributes-featured {
        padding: 1rem 1.5rem;
        background-color: $color-background-accent;

        @include media-breakpoint-up(lg) {
            columns: 2;
            column-gap: 1rem;
        }
    }

    .pdp-attributes,
    .substitute-products,
    .ms-content-block { // @CUSTOM
        @include media-breakpoint-up(xl) {
            margin-left: 45%;
        }
    }

    // @VO: This needs to be redone - this is targeting any content block that
    // is added and is not specific enough.
    .ms-content-block {
        &__text {
            @include center-flex(y);
    
            color: $color-rainforest;
            font-weight: $font-weight-bold;

            @include icon-image(
                $asset-icon-info,
                $width: 19px,
                $color: $color-rainforest
            );
            

            &::before {
                margin-right: 1rem;
            }

            p > * {
                color: $color-lunar;
                margin-left: 0.75rem;
            }

            @include media-breakpoint-down(md) { 
                display: grid;
                grid-template-columns: 1fr 11fr;
                align-items: unset;
            }
        }
    }
}
