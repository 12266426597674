// Outside Footer Container
footer {
    @extend %width-page-full;
    background-color: $color-background-container-footer;
}

// Main Footer
.footer {
    @extend %width-container-footer;

    > .container-with-header__slots {
        padding: $padding-footer-container__mobile;

        @include media-breakpoint-up(xl) {
            display: flex;
            justify-content: space-between;
            padding: $padding-container-footer;
        }

        // Footer Columns
        > * {
            margin-bottom: $margin-bottom-container-footer-column__mobile-down;

            &:last-child {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(xl) {
                margin-right: $margin-right-container-footer-column__desktop-up;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    // Footer Logo
    &-logo img {
        max-width: $max-width-img-footer-logo__mobile;

        @include media-breakpoint-up(lg) {
            max-width: $max-width-img-footer-logo;
        }
    }

    // Footer Nav
    &-nav {
        @include media-breakpoint-up(xl) {
            flex: 1;
        }

        > .container-with-header__slots {
            @include media-breakpoint-up(xs) {
                display: flex;
                flex-wrap: wrap;
            }

            // Footer Nav Columns
            > * {
                // Footer Nav Columns for Mobile
                margin-bottom: $margin-bottom-container-footer-nav-column__mobile-down;

                &:last-child {
                    margin-bottom: 0;
                }

                // Footer Nav Columns for Tablet
                @include media-breakpoint-up(xs) {
                    @include flex-width($width-container-footer-nav-column__tablet-up);
                    margin-right: $margin-right-container-footer-nav-column__tablet-up;

                    &:nth-child(2n) {
                        margin-right: 0;
                    }

                    &:last-child,
                    &:nth-last-child(2):nth-child(2n-1) {
                        margin-bottom: 0;
                    }
                }

                // Footer Nav Columns for Desktop
                @include media-breakpoint-up(md) {
                    &,
                    &:nth-child(2n) {
                        @include flex-width($width-container-footer-nav-column__desktop-up);
                        margin-right: $margin-right-container-footer-nav-column__tablet-up;
                        margin-bottom: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    // Footer Nav Link
    &-links .ms-footer__list-item {
        margin-bottom: $margin-bottom-link-footer__mobile;

        &:first-child {
            margin-bottom: $margin-bottom-link-footer;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    // Footer Legal Column
    &-legal > .container-with-header__slots {
        @include media-breakpoint-up(xl) {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        // Footer Legal Rows
        > * {
            margin-bottom: $margin-bottom-container-footer-legal-row__mobile;

            @include media-breakpoint-up(xl) {
                margin-bottom: $margin-bottom-container-footer-legal-row;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    // Footer Social Row
    &-social {
        > .container-with-header__slots {
            display: flex;
            flex-wrap: wrap;

            // Footer Social Icons
            > * {
                margin-right: $margin-right-icon-footer-social;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        // Footer Social Icons
        @each $social, $icon in $icons-footer-social {
            &-#{$social} {
                .ms-content-block__link,
                .ms-content-block__cta .msc-cta__primary {
                    @include icon-footer-social($icon);
                }
            }
        }

        // Footer Social Icon Links
        .ms-content-block__link,
        .ms-content-block__cta .msc-cta__primary {
            @extend %link-footer-social;
            font-size: $font-size-icon-footer-social;
        }
    }

    // Footer Accessibility Icon
    &-accessibility {
        .ms-content-block__link,
        .ms-content-block__cta .msc-cta__primary {
            @extend %icon-footer-accessibility;
        }
    }

    // Footer Copyright
    &-copyright {
        @extend %text-footer-copyright;
    }

    // Footer Item
    .ms-footer__item {
        @extend %text-footer;
    }

    // Footer Heading
    .ms-footer__heading__title {
        @extend %heading-footer;
    }

    // Footer Link
    .ms-footer__link {
        @extend %link-footer;
    }
}
