// Outside Header Container
header:not([class]) {
    @extend %width-page-full;
    background-color: $color-background-container-header;

    @include media-breakpoint-down(lg) {
        position: fixed;
        z-index: 10;
    }

    + * {
        @include media-breakpoint-down(lg) {
            padding-top: $height-container-header__tablet;
        }

        @include media-breakpoint-down(xs) {
            padding-top: $height-container-header__mobile;
        }
    }
}

// Main Header
.header {
    position: relative;
    z-index: 1001;
    background-color: $color-background-container-header;

    // Hide main header minicart when sticky header is active
    .sticky & .ms-cart-icon__flyout-container {
        display: none !important;
    }
}

// Sticky Header
.header-sticky {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    max-width: none !important;
    background-color: $color-background-container-header;

    @include media-breakpoint-down(lg) {
        display: none !important;
    }

    .sticky & {
        @include animation-fadeInDown;
        display: block;
    }

    .ms-header__container {
        @extend %width-container-header;
        padding-top: $padding-y-container-header-sticky;
        padding-bottom: $padding-y-container-header-sticky;
    }

    .ms-header__topbar-center {
        @include media-breakpoint-up(xl) {
            order: -1;
        }
    }

    .ms-header__topbar-left,
    .ms-header__topbar-right {
        margin-top: 0;
    }

    .ms-header__topbar-right {
        flex: unset;
    }

    .ms-header__bottombar {
        display: none;
    }

    .ms-header__logo img {
        max-width: 42px;
    }

    .ms-search {
        @include media-breakpoint-up(xl) {
            position: initial;
        }

        &__form {
            @include media-breakpoint-up(xl) {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                padding: $padding-container-search__tablet-down;
                background-color: $color-background-container-search__tablet-down;
            }
        }

        &__searchForm {
            @include media-breakpoint-up(xl) {
                position: initial;
                justify-content: space-between;
                width: 100%;
            }

            &::after {
                @include media-breakpoint-up(xl) {
                    position: initial;
                    right: unset;
                }
            }
        }

        &__autoSuggest {
            @include media-breakpoint-up(xl) {
                @include center-absolute(x);
                top: calc(100% + #{$pos-top-container-search-results__mobile} - #{$padding-y-search__tablet-down});
                width: $width-input-search;
            }
        }

        &__icon,
        &__form-cancelSearch,
        &__form-cancelSearchNoSumbit,
        &__searchForm::before {
            @include media-breakpoint-up(xl) {
                display: initial;
            }
        }

        &__form.bx-hide,
        &__form.bx-hide + .ms-search__autoSuggest,
        &__searchForm::after {
            display: none;
        }
    }
}

// Desktop & Mobile Header
.ms-header,
.ms-header__modal {
    @extend %text-header;

    // User & Cart
    .ms-header__signin-button,
    .ms-header__profile-button,
    .msc-cart-icon {
        @include center-flex(y);

        span {
            margin-left: $margin-left-text-header-icon;
        }
    }

    // User Icon
    .ms-header__signin-button,
    .ms-header__profile-button {
        @include button-remove;
        @include icon-header(user);
    }

    // Cart Icon
    .msc-cart-icon {
        @include icon-header(cart);
    }

    // Nav Icon
    .ms-header__nav-icon {
        @include button-remove;
        @include icon-header(nav);
    }

    // Close Icon
    .msc-modal__close-button {
        @include button-remove;
        @include icon-header(close);
        float: right;
    }

    // Back Icon
    .ms-nav__list__mobile__container__button {
        @include icon-header(back);
    }
}

// Desktop Header
.ms-header {
    @extend %width-container-header;

    &__container {
        padding: $padding-container-header;
    }

    // Top Bar
    &__topbar {
        @include center-flex(y);
        justify-content: space-between;

        > *,
        > * > * {
            @include center-flex(y);
            margin-right: $margin-right-container-header-top-column__mobile;

            @include media-breakpoint-up(sm) {
                margin-right: $margin-right-container-header-top-column;
            }

            &.ms-search {
                @include media-breakpoint-up(xl) {
                    margin-right: $margin-right-container-header-search__desktop-up;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &-left,
        &-right {
            flex: 1;

            @include media-breakpoint-up(sm) {
                margin-top: $margin-top-container-header-top-column__tablet-up;
            }
        }

        &-center {
            justify-content: center;
        }

        &-right {
            justify-content: flex-end;
        }
    }

    // Bottom Bar
    &__bottombar {
        @include center-flex;
        padding-top: $padding-top-container-header-bottom__desktop-up;
        margin-top: $margin-top-container-header-bottom__desktop-up;
        border-top: $border-top-container-header-bottom__desktop-up;
    }

    // Logo
    &__logo img {
        max-width: $max-width-img-header-logo__mobile;

        @include media-breakpoint-up(sm) {
            max-width: $max-width-img-header-logo;
        }
    }

    // Account Dropdown
    &__account-info-content {
        z-index: $z-index-container-header-account-dropdown;

        .msc-popover-inner {
            margin-top: $margin-top-container-header-account-dropdown;
            background-color: $color-background-header-account-dropdown;
        }

        button,
        a {
            @extend %link-header-account-dropdown;
            display: block;
            padding: $padding-link-header-account-dropdown;
            text-align: center;

            &:first-child {
                padding-top: $padding-top-link-header-account-dropdown;
            }

            &:last-child {
                padding-bottom: $padding-bottom-link-header-account-dropdown;
            }
        }
    }

    // User & Cart Text
    .ms-header__signin-button-text,
    .ms-profile-button-text,
    .msc-cart-icon__count {
        display: none;
    }
}

// Mobile Header
.ms-header {
    &__modal {
        position: absolute;
        overflow-y: auto;
        z-index: 20;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        padding: $padding-container-header__mobile;
        background-color: $color-background-container-header;
    
        .header-nav-main {
            margin-top: $margin-top-container-header-nav-main__mobile-down;
        }
    
        .header-nav-sub {
            margin-top: $margin-top-container-header-nav-sub__mobile-down;
        }
    }

    // Account Links
    &__mobile-hamburger-menu-links {
        margin-top: $margin-top-container-header-account-mobile-links;

        a,
        button {
            @extend %link-header-account-mobile;
            display: block;
            padding: $padding-link-header-account-dropdown-mobile;
        }
    }
}

// Hide on Mobile
.ms-header__topbar .header-nav-sub,
.ms-header__topbar .ms-header__account-info,
.ms-header__bottombar {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

// Hide on Desktop
.ms-header__nav-icon,
.ms-header__modal {
    @include media-breakpoint-up(xl) {
        display: none;
    }
}

// Header Navigation
.header-nav {
    &-main {
        > .ms-nav__list > .ms-nav__list__item {
            @include media-breakpoint-up(xl) {
                margin-right: $margin-right-link-header-nav-main__desktop-up;
            }
        }

        .ms-nav__list__item__button,
        .ms-nav__list__item__link,
        .ms-nav__list__item__span {
            @extend %link-header-nav-main;
        }
    }

    &-sub {
        .ms-nav__list__item__button,
        .ms-nav__list__item__link,
        .ms-nav__list__item__span {
            @extend %link-header-nav-sub;
        }
    }

    &-main,
    &-sub {
        .ms-nav__deafult {
            .ms-nav__list__item__button,
            .ms-nav__list__item__link,
            .ms-nav__list__item__span {
                @extend %link-header-nav-dropdown;
            }
        }

        .ms-nav__list__mobile__container {
            @extend %heading-header-nav-dropdown;
        }
    }
}
