.ms-refine-submenu {
    &__list {
        margin-bottom: 2rem !important;
    }

    &:last-child .ms-refine-submenu__list {
        margin-bottom: 0 !important;
    }

    &__toggle_expanded,
    &__toggle_collapsed {
        @include center-flex(y);
        @include button-remove;
        @extend %heading-h6;
        @extend %text-small;
        justify-content: space-between;
        margin-bottom: 1rem;
        text-align: left;
    }

    &__toggle_expanded {
        @include icon-image(
            $asset-icon-minus,
            after,
            14px,
            1px,
            $color-text
        );
    }

    &__toggle_collapsed {
        @include icon-image(
            $asset-icon-plus,
            after,
            $width: 14px,
            $color: $color-text
        );
    }

    &-item {
        @extend %text-small;

        // List item and link have the same class, we want to only target the list item
        @at-root li#{&} {
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            &::before {
                @include transition;
                content: '';
                display: inline-block;
                width: 12px;
                height: 12px;
                margin-right: 1rem;
                border: 1px solid $color-border;
            }

            &.single-select::before,
            &.single-select-checked::before {
                border-radius: 50%;
            }

            &.multi-select-checked::before,
            &.single-select-checked::before {
                border-color: $color-background-neutral;
                background-color: $color-background-neutral;
            }

            &:hover {
                &::before {
                    border-color: $color-border;
                    background-color: $color-border;
                }

                &.multi-select-checked::before,
                &.single-select-checked::before {
                    border-color: $color-border;
                    background-color: $color-border;
                }
            }
        }
    }
}
