// Account Dropdown Icon
$size-icon-account-dropdown: 7px;
$margin-left-icon-account-dropdown: 0.5rem;
$color-icon-account-dropdown: $color-text;

%icon-account-dropdown {
    content: '';
    width: 0;
    height: 0;
    margin-left: $margin-left-icon-account-dropdown;
    border-left: $size-icon-account-dropdown solid transparent;
    border-right: $size-icon-account-dropdown solid transparent;

    &-expand {
        border-top: $size-icon-account-dropdown solid $color-icon-account-dropdown;
    }

    &-collapse {
        border-bottom: $size-icon-account-dropdown solid $color-icon-account-dropdown;
    }
}

// Account Dropdown Container
$padding-container-account-dropdown: 0.5rem 1rem 0.75rem;
$border-container-account-dropdown: 1px solid $color-border-inverse-accent;
$box-shadow-container-account-dropdown: 0 0 6px transparentize($color-border-inverse-accent, 0.5);
$color-background-container-account-dropdown: $color-background;

%container-account-dropdown {
    padding: $padding-container-account-dropdown;
    border: $border-container-account-dropdown;
    box-shadow: $box-shadow-container-account-dropdown;
    background-color: $color-background-container-account-dropdown;
}

// Account Section Icons
$width-icon-account-section: 24px;
$margin-right-icon-account-section: 1rem;
$margin-right-icon-account-section-heading: 0.5rem;
$color-icon-account-section: $color-link-muted;
$color-icon-account-section--active: $color-link-inverse;

$icons-account-section: (
    profile: $asset-icon-user,
    address: $asset-icon-location,
    orderhistory: $asset-icon-cart,
    invoice: $asset-icon-invoice,
    accountbalance: $asset-icon-contract,
    organization: $asset-icon-users,
    wishlist: $asset-icon-heart,
    ordertemplate: $asset-icon-document
);

$icons-account-section-width: (
    profile: 24px,
    address: 17px,
    orderhistory: 20px,
    invoice: 18px,
    accountbalance: 16px,
    organization: 20px,
    wishlist: 20px,
    ordertemplate: 16px
);

$icons-account-section-height: (
    profile: map-get($icons-account-section-width, profile),
    address: 24px,
    orderhistory: 17px,
    invoice: 20px,
    accountbalance: 20px,
    organization: 16px,
    wishlist: 18px,
    ordertemplate: 20px
);

@mixin icon-account-section($icon) {
    @include icon-image(
        map-get($icons-account-section, $icon),
        $width: map-get($icons-account-section-width, $icon),
        $height: map-get($icons-account-section-height, $icon),
        $color: $color-icon-account-section,
        $fit: contain
    );
}
