// Pagination
$width-container-pagination: 100%;

// Pagination Page
$padding-text-pagination-page: 0.5rem 1rem;
$padding-text-pagination-page__mobile: 0.25rem 0.5rem;
$padding-text-pagination-page__mobile-small: 0.15rem 0.35rem;
$color-text-pagination-page: $color-link-accent;
$color-text-pagination-page--active: $color-text;
$color-text-pagination-page--disabled: $color-text-muted;
$font-weight-text-pagination-page--active: $font-weight-bold;
$text-transform-text-pagination-page: lowercase;

%text-pagination-page {
    @extend %text;
    @extend %text-small;
    @include text-text-transform($text-transform-text-pagination-page);
    color: $color-text-pagination-page;

    &-active {
        @include link-remove($color-text-pagination-page--active);
        @include text-font-weight($font-weight-text-pagination-page--active);
    }

    &-disabled {
        @include link-disable;
        color: $color-text-pagination-page--disabled;
    }
}
