//==============================================================================
// COMPONENT COLORS
//==============================================================================

// Border
$color-border: $color-tea;
$color-border-inverse: $color-lunar;
$color-border-accent: $color-soya;
$color-border-inverse-accent: $color-mantle;
$color-border-muted: $color-pampas;

// Background
$color-background: $color-white;
$color-background-inverse: $color-lunar;
$color-background-accent: $color-pampas;
$color-background-inverse-accent: $color-soya;
$color-background-neutral: $color-ronchi;
$color-background-bold: $color-fire;
$color-background-muted: $color-mercury;

// Text
$color-text: $color-lunar;
$color-text-inverse: $color-white;
$color-text-accent: $color-soya;
$color-text-neutral: $color-lunar;
$color-text-muted: $color-zorba;

$color-text-featured: $color-text;
$color-text-featured-inverse: $color-text-inverse;
$color-text-featured-accent: $color-text-accent;
$color-text-featured-muted: $color-text-muted;

$color-text-highlight: $color-text;
$color-text-highlight-inverse: $color-text-inverse;
$color-text-highlight-accent: $color-text-accent;
$color-text-highlight-muted: $color-text-muted;

$color-text-appellation: $color-text;
$color-text-appellation-inverse: $color-text-inverse;
$color-text-appellation-accent: $color-text-accent;
$color-text-appellation-muted: $color-text-muted;

// Heading
$color-heading: $color-lunar;
$color-heading-inverse: $color-white;
$color-heading-accent: $color-soya;
$color-heading-muted: $color-zorba;
$color-heading-featured: $color-carmine;

// Link
$color-link: $color-lunar;
$color-link--hover: $color-link;

$color-link-inverse: $color-white;
$color-link-inverse--hover: $color-link-inverse;

$color-link-accent: $color-carmine;
$color-link-accent--hover: $color-link-accent;

$color-link-inverse-accent: $color-tea;
$color-link-inverse-accent--hover: $color-link-inverse-accent;

$color-link-muted: $color-zorba;
$color-link-muted--hover: $color-link-muted;

// Button
$color-button__border: $color-ronchi;
$color-button__background: $color-ronchi;
$color-button__text: $color-lunar;
$color-button__border--hover: $color-button__border;
$color-button__background--hover: $color-button__background;
$color-button__text--hover: $color-button__text;

$color-button-disabled__border: transparentize($color-button__border, 0.25);
$color-button-disabled__background: transparentize($color-button__border, 0.25);
$color-button-disabled__text: transparentize($color-button__text, 0.25);

$color-button-inverse__border: $color-lunar;
$color-button-inverse__background: $color-lunar;
$color-button-inverse__text: $color-ronchi;
$color-button-inverse__border--hover: $color-button-inverse__border;
$color-button-inverse__background--hover: $color-button-inverse__background;
$color-button-inverse__text--hover: $color-button-inverse__text;

$color-button-inverse-disabled__border: transparentize($color-button-inverse__border, 0.25);
$color-button-inverse-disabled__background: transparentize($color-button-inverse__border, 0.25);
$color-button-inverse-disabled__text: transparentize($color-button-inverse__text, 0.25);

$color-button-featured__border: transparent;
$color-button-featured__background: transparent;
$color-button-featured__text: $color-carmine;
$color-button-featured__border--hover: $color-button-featured__border;
$color-button-featured__background--hover: $color-button-featured__background;
$color-button-featured__text--hover: $color-button-featured__text;

$color-button-featured-disabled__border: transparent;
$color-button-featured-disabled__background: transparent;
$color-button-featured-disabled__text: $color-mantle;

$color-button-featured-inverse__border: transparent;
$color-button-featured-inverse__background: transparent;
$color-button-featured-inverse__text: $color-white;
$color-button-featured-inverse__border--hover: $color-button-featured__border;
$color-button-featured-inverse__background--hover: $color-button-featured__background;
$color-button-featured-inverse__text--hover: $color-button-featured__text;

$color-button-featured-inverse-disabled__border: transparent;
$color-button-featured-inverse-disabled__background: transparent;
$color-button-featured-inverse-disabled__text: $color-mantle;

$color-button-underline__border: $color-ronchi;
$color-button-underline__background: transparent;
$color-button-underline__text: $color-lunar;
$color-button-underline__border--hover: $color-button-underline__border;
$color-button-underline__background--hover: $color-button-underline__background;
$color-button-underline__text--hover: $color-button-underline__text;

$color-button-underline-disabled__border: $color-mantle;
$color-button-underline-disabled__background: transparent;
$color-button-underline-disabled__text: transparentize($color-button-underline__text, 0.25);

$color-button-underline-inverse__border: $color-ronchi;
$color-button-underline-inverse__background: transparent;
$color-button-underline-inverse__text: $color-white;
$color-button-underline-inverse__border--hover: $color-button-underline__border;
$color-button-underline-inverse__background--hover: $color-button-underline__background;
$color-button-underline-inverse__text--hover: $color-button-underline__text;

$color-button-underline-inverse-disabled__border: $color-mantle;
$color-button-underline-inverse-disabled__background: transparent;
$color-button-underline-inverse-disabled__text: transparentize($color-button-underline__text, 0.25);

$color-button-option__border: $color-mercury;
$color-button-option__background: $color-mercury;
$color-button-option__text: $color-lunar;
$color-button-option__border--hover: $color-button-option__border;
$color-button-option__background--hover: $color-button-option__background;
$color-button-option__text--hover: $color-button-option__text;

$color-button-option-disabled__border: transparentize($color-button-option__border, 0.25);
$color-button-option-disabled__background: transparentize($color-button-option__border, 0.25);
$color-button-option-disabled__text: transparentize($color-button-option__text, 0.25);

$color-button-option-inverse__border: $color-lunar;
$color-button-option-inverse__background: $color-lunar;
$color-button-option-inverse__text: $color-white;
$color-button-option-inverse__border--hover: $color-button-option__border;
$color-button-option-inverse__background--hover: $color-button-option__background;
$color-button-option-inverse__text--hover: $color-button-option__text;

$color-button-option-inverse-disabled__border: transparentize($color-button-option__border, 0.25);
$color-button-option-inverse-disabled__background: transparentize($color-button-option__border, 0.25);
$color-button-option-inverse-disabled__text: transparentize($color-button-option__text, 0.25);

$color-button-primary__border: $color-lunar;
$color-button-primary__background: $color-lunar;
$color-button-primary__text: $color-white;
$color-button-primary__border--hover: $color-button-primary__border;
$color-button-primary__background--hover: $color-button-primary__background;
$color-button-primary__text--hover: $color-button-primary__text;

$color-button-primary-disabled__border: transparentize($color-button-primary__border, 0.25);
$color-button-primary-disabled__background: transparentize($color-button-primary__background, 0.25);
$color-button-primary-disabled__text: transparentize($color-button-primary__text, 0.25);

$color-button-primary-inverse__border: $color-white;
$color-button-primary-inverse__background: $color-white;
$color-button-primary-inverse__text: $color-lunar;
$color-button-primary-inverse__border--hover: $color-button-primary-inverse__border;
$color-button-primary-inverse__background--hover: $color-button-primary-inverse__background;
$color-button-primary-inverse__text--hover: $color-button-primary-inverse__text;

$color-button-primary-inverse-disabled__border: transparentize($color-button-primary-inverse__border, 0.25);
$color-button-primary-inverse-disabled__background: transparentize($color-button-primary-inverse__background, 0.25);
$color-button-primary-inverse-disabled__text: transparentize($color-button-primary-inverse__text, 0.25);

$color-button-secondary__border: $color-lunar;
$color-button-secondary__background: transparent;
$color-button-secondary__text: $color-lunar;
$color-button-secondary__border--hover: $color-button-secondary__border;
$color-button-secondary__background--hover: $color-button-secondary__background;
$color-button-secondary__text--hover: $color-button-secondary__text;

$color-button-secondary-disabled__border: transparentize($color-button-secondary__border, 0.25);
$color-button-secondary-disabled__background: transparentize($color-button-secondary__background, 0.25);
$color-button-secondary-disabled__text: transparentize($color-button-secondary__text, 0.25);

$color-button-secondary-inverse__border: $color-white;
$color-button-secondary-inverse__background: transparent;
$color-button-secondary-inverse__text: $color-white;
$color-button-secondary-inverse__border--hover: $color-button-secondary-inverse__border;
$color-button-secondary-inverse__background--hover: $color-button-secondary-inverse__background;
$color-button-secondary-inverse__text--hover: $color-button-secondary-inverse__text;

$color-button-secondary-inverse-disabled__border: transparentize($color-button-secondary-inverse__border, 0.25);
$color-button-secondary-inverse-disabled__background: transparentize($color-button-secondary-inverse__background, 0.25);
$color-button-secondary-inverse-disabled__text: transparentize($color-button-secondary-inverse__text, 0.25);

$color-button-tertiary__border: $color-tea;
$color-button-tertiary__background: $color-tea;
$color-button-tertiary__text: $color-lunar;
$color-button-tertiary__border--hover: $color-button-tertiary__border;
$color-button-tertiary__background--hover: $color-button-tertiary__background;
$color-button-tertiary__text--hover: $color-button-tertiary__text;

$color-button-tertiary-disabled__border: transparentize($color-button-tertiary__border, 0.25);
$color-button-tertiary-disabled__background: transparentize($color-button-tertiary__background, 0.25);
$color-button-tertiary-disabled__text: transparentize($color-button-tertiary__text, 0.25);

$color-button-tertiary-inverse__border: $color-tea;
$color-button-tertiary-inverse__background: $color-tea;
$color-button-tertiary-inverse__text: $color-white;
$color-button-tertiary-inverse__border--hover: $color-button-tertiary-inverse__border;
$color-button-tertiary-inverse__background--hover: $color-button-tertiary-inverse__background;
$color-button-tertiary-inverse__text--hover: $color-button-tertiary-inverse__text;

$color-button-tertiary-inverse-disabled__border: transparentize($color-button-tertiary-inverse__border, 0.25);
$color-button-tertiary-inverse-disabled__background: transparentize($color-button-tertiary-inverse__background, 0.25);
$color-button-tertiary-inverse-disabled__text: transparentize($color-button-tertiary-inverse__text, 0.25);

// Input
$color-input__border: $color-mantle;
$color-input__background: $color-white;
$color-input__text: $color-lunar;
$color-input__border--active: $color-zorba;
$color-input__background--active: $color-input__background;
$color-input__text--active: $color-input__text;
$color-input__border--error: $color-red;
$color-input__background--error: $color-input__background;
$color-input__text--error: $color-input__text;

// Label
$color-label: $color-zorba;
