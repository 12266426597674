.ms-business-organization-list {
    width: 100%;

    &__heading {
        margin-bottom: 1rem;
    }

    &__buttonBar {
        display: flex;
        align-items: flex-end;
        margin-bottom: 1rem;

        @include media-breakpoint-down(xs) {
            flex-wrap: wrap;
        }
    }

    &__add-user-button {
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }

    &__dropdown {
        display: none !important;

        @include media-breakpoint-down(xs) {
            width: 100%;
            margin-top: 0.5rem;
        }

        @include media-breakpoint-up(sm) {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            margin-left: 1rem;
        }

        .msc-popover-inner {
            @extend %container-account-dropdown;
        }

        button {
            @include button-remove;
            display: block;
            text-align: left;

            @include media-breakpoint-up(sm) {
                margin-left: auto;
                text-align: right;
            }

            &.ms-business-organization-list__action__requestStatement__toggleDown,
            &.ms-business-organization-list__action__requestStatement__toggleUp {
                @include center-flex(y);

                &::after {
                    @extend %icon-account-dropdown;
                }
            }

            &.ms-business-organization-list__action__requestStatement__toggleDown::after {
                @extend %icon-account-dropdown-expand;
            }

            &.ms-business-organization-list__action__requestStatement__toggleUp::after {
                @extend %icon-account-dropdown-collapse;
            }
        }
    }

    &__error-message {
        margin-top: 1rem;
    }

    .ms-table__container {
        overflow-x: auto;
        margin-top: 2rem;
    }

    &__table {
        @extend %text-small;
        width: 100%;

        @include media-breakpoint-up(md) {
            min-width: 600px;
        }

        thead {
            border-bottom: 1px solid $color-border;
            text-transform: uppercase;
        }

        .ms-table__heading-row,
        .ms-table__row {
            > * {
                padding: 0.25rem;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                // None of the table columns have classes
                &:first-child { // Select checkbox
                    width: 24px;
                }

                // Hiding spending limit
                &:nth-child(5) {
                    display: none;
                }
            }
        }

        .ms-table__row {
            &:first-child > * {
                padding-top: 0.5rem;
            }

            &:last-child > * {
                padding-bottom: 0.5rem;
            }
        }

        // Action buttons only for desktop
        .ms-table__row-data > .ms-table__row-links {
            @include center-flex(y);

            .ms-table__row-links-edit {
                @extend %button-organization-list-product-edit;
            }

            .ms-table__row-links-delete {
                @extend %button-minicart-product-remove;
            }
        }
    }

    .ms-table-business-org__pagination {
        margin-top: 2rem;
    }

    &__modal .msc-modal__dialog {
        @include media-breakpoint-up(md) {
            min-width: 400px !important;
        }
    }

    &__form-all-required-msg {
        @extend %text-small;
    }

    &__form-item {
        margin-bottom: 0.5rem;

        &:last-child {
            margin-bottom: 0;
            display: none;
        }

        &-label {
            @extend %label;
        }

        &-input {
            @extend %input;
        }

        &__item-error-text {
            @extend %alert;
            @extend %alert-danger;
            margin-bottom: 0.5rem !important;
        }

        // Hiding spending limit
        &:nth-child(4) {
            display: none;
        }
    }

    &__requestStatement__fromDate,
    &__requestStatement__toDate {
        margin-bottom: 0.5rem;

        label {
            @extend %label;
        }

        input {
            @extend %input;
        }
    }

    &__form-save,
    &__action__submitRequest {
        @extend %button-primary;

        &:disabled,
        &.disabled {
            @extend %button-primary-disabled;
        }
    }

    &__form-cancel,
    &__action__cancelRequest {
        @extend %button-secondary;
        margin-left: 0.75rem;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }

    &__sendToEmail {
        margin-top: -0.5rem;
    }

    &__sendToEmail,
    &__selectedUser {
        @extend %text-small;
        @extend %text-muted;
        margin-bottom: 1rem;
    }

    &__form-remove-user-button {
        @extend %button-primary;
    }
}
