.add-to-cart-pallet {
    @include media-breakpoint-up(md) {
        display: contents;
    }

    @include media-breakpoint-down(sm) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    &__divider {
        background-color: $color-border-inverse-accent;

        @media screen and (min-width: $breakpoint-xxl) {
            width: 1px;
            height: 50px;
            margin: 0 1.5rem;
        }

        @media screen and (min-width: $breakpoint-xl) and (max-width: $breakpoint-xxl) {
            width: 100%;
            height: 1px;
            margin: 1.5rem 0;
        }

        @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-xl) {
            width: 1px;
            height: 50px;
            margin: 0 1.5rem;
        }

        @media screen and (max-width: $breakpoint-md) {
            width: 100%;
            height: 1px;
            margin: 1.5rem 0;
        }
    }

    &__qty-input {
        @extend %input;
        width: 60px;
        height: 50px;
        text-align: center;
        margin-right: 1rem;
    }

    &__button {
        @include button;
        @include icon-image(
            $asset-icon-pallet,
            $width: 33px,
            $height: 30px,
            $color: $color-lunar
        );
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 1fr 1fr;
        align-items: center;
        gap: 0 0.5rem;
        padding: 0.35rem 0.75rem 0.25rem;

        &::before {
            grid-column: 1/2;
            grid-row: 1/3;
        }

        &-top {
            font-size: $font-size-button;
            font-weight: $font-weight-black;
            margin-bottom: -3px;
        }

        &-bottom {
            font-size: 13px;
            letter-spacing: 0;
        }

        &-top,
        &-bottom {
            text-align: start !important;
        }
    }

    &__cart-message {
        @extend %alert;
        @extend %alert-danger;
        order: 11;
        width: 100%;
        margin-top: 1rem;
    }
}
