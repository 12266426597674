$width-icon-quick-order-dropdown: 17px;
$height-icon-quick-order-dropdown: 10px;
$color-icon-quick-order-dropdown: $color-text;
$icon-quick-order-dropdown-expand: $asset-icon-chevron-down;
$icon-quick-order-dropdown-collapse: $asset-icon-chevron-up;

@mixin icon-quick-order-dropdown($icon) {
    @include icon-image(
        $icon,
        $width: $width-icon-quick-order-dropdown,
        $height: $height-icon-quick-order-dropdown,
        $color: $color-icon-quick-order-dropdown
    );
}

%icon-quick-order-dropdown {
    &-expand {
        @include icon-quick-order-dropdown($icon-quick-order-dropdown-expand);
    }

    &-collapse {
        @include icon-quick-order-dropdown($icon-quick-order-dropdown-collapse);
    }
}

.ms-quick-order {
    margin-top: 2rem;
    margin-bottom: 2rem;

    .drawer__button {
        @include button-remove;
        @include center-flex(x);
        flex-direction: column;
        position: relative;
        padding: 0.75rem 1rem 0.5rem;
        padding-right: 3rem;

        @include media-breakpoint-up(md) {
            padding: 0.75rem 2rem 0.5rem;
            padding-right: 5rem;
        }

        // Drawer button also comes with btn-secondary class, which has focus
        // and active state styling that needs to be overridden
        &,
        &:focus,
        &:active {
            border: 1px solid $color-border !important;
            background-color: transparent !important;
        }

        > * {
            margin: 0;
            margin-right: 1rem;
            text-align: left;

            &:last-child {
                margin-right: 0;
            }
        }

        .drawer__glyph {
            position: absolute;
            right: 1rem;

            @include media-breakpoint-up(md) {
                padding: 2rem;
            }
        }

        &[aria-expanded='true'] .drawer__glyph {
            @extend %icon-quick-order-dropdown-collapse;
        }

        &[aria-expanded='false'] .drawer__glyph {
            @extend %icon-quick-order-dropdown-expand;
        }
    }

    &__heading {
        @extend %text;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

    &__description {
        @extend %text;
        @extend %text-small;
        @extend %text-muted;
    }

    &__content {
        padding: 1rem;
        border: 1px solid $color-border;
        border-top: 0;

        @include media-breakpoint-up(md) {
            padding: 2rem;
        }

        &-form-msg .msc-alert {
            @extend %alert;
            @extend %alert-success;
            margin-bottom: 1rem;
        }

        &-form-msg .msc-alert-danger {
            @extend %alert-danger;
        }

        &-form-main {
            &,
            &__configure {
                @include media-breakpoint-up(md) {
                    display: flex;
                    align-items: flex-end;
                }

                > * {
                    margin-bottom: 1rem;

                    @include media-breakpoint-up(md) {
                        margin-right: 1rem;
                        margin-bottom: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                        margin-right: 0;
                    }
                }
            }

            &-product,
            &-quantity,
            &__dropdown {
                &-lable, // Label class is misspelled on some but not others
                &-quantity-label {
                    @extend %label;
                }

                &-input {
                    @extend %input;
                }

                .msc-dropdown__select {
                    @extend %select;

                    &__disabled {
                        color: #8080806b;
                    }
                }

                &-input,
                .msc-dropdown__select {
                    @include media-breakpoint-up(md) {
                        height: 42px;
                    }
                }

                .msc-alert-danger {
                    @extend %alert-danger;
                }
            }

            &-addtocart-button {
                @extend %button-primary;
                @include button-width-full__mobile-only;

                @include media-breakpoint-up(md) {
                    height: 42px;
                    min-width: 140px;
                }

                &:disabled,
                &.disabled {
                    @extend %button-primary-disabled;
                }
            }
        }
    }
}
