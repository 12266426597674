.ms-price,
.msc-price {
    &__strikethrough {
        @extend %text-muted;
        margin-right: 0.5rem;
        text-decoration: line-through;
    }

    &__salesagreementprompt_expanded,
    &__salesagreementprompt_collapsed {
        @extend %link;
        @extend %link-accent;
        @include link-text-decoration(underline);
        min-width: 130px;
        margin-bottom: 1rem;
    }

    &__salesagreementdetail p {
        margin-bottom: 0.5rem;
    }
}
