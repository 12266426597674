// AAD
$width-container-aad: 100%;
$max-width-container-aad: 500px;
$padding-container-aad: 2rem;
$margin-top-general-aad: 1rem;
$margin-bottom-general-aad: 1rem;

// AAD Input
$width-input-aad: 100%;
$margin-top-input-aad: 0.25rem;

// AAD Button
$width-button-aad: 100%;

// AAD Link
$margin-top-link-aad: 1rem;

// AAD Page Error
$margin-bottom-page-aad-error: 1rem;

// AAD Create Account
$margin-left-container-aad-create-account: 0.5rem;

// AAD Alert
$padding-alert-aad: 0.5rem 1rem;
$margin-top-text-aad-message: 1rem;
