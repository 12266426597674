.ms-checkout-payment-instrument {
    .checkout-payment-instrument__iframe {
        border: 0;
        margin-left: -7px;
    }

    &__card-title {
        @extend %heading-h6;
        @extend %text-small;
        @extend %text-muted;
        margin-bottom: 0.5rem;
    }

    &__card-name {
        font-weight: $font-weight-bold;
    }

    &__show > *:not(.ms-checkout-payment-instrument__card-title) {
        @extend %text-small;
        margin-bottom: 0;
    }
}
