//==============================================================================
// COMPONENT FONTS
//==============================================================================

// Text
$font-family-text: $font-family-proximanova;
$font-family-text-featured: $font-family-text;
$font-family-text-highlight: $font-family-roboto;
$font-family-text-appellation: $font-family-text;

// Heading
$font-family-heading: $font-family-proximanova;
$font-family-heading-h1: $font-family-heading;
$font-family-heading-h2: $font-family-heading;
$font-family-heading-h3: $font-family-heading;
$font-family-heading-h4: $font-family-heading;
$font-family-heading-h5: $font-family-heading;
$font-family-heading-h6: $font-family-heading;
$font-family-heading-featured: $font-family-roboto;

// Button
$font-family-button: $font-family-proximanova;
$font-family-button-featured: $font-family-roboto;
$font-family-button-underline: $font-family-proximanova;
$font-family-button-option: $font-family-proximanova;
$font-family-button-primary: $font-family-button;
$font-family-button-secondary: $font-family-button-primary;
$font-family-button-tertiary: $font-family-button-primary;

// Input
$font-family-input: $font-family-proximanova;

// Label
$font-family-label: $font-family-proximanova;

//==============================================================================
// FONT SIZES
//==============================================================================

// Text
$font-size-text: 17px;
$font-size-text__mobile: $font-size-text;

$font-size-text-large: 20px;
$font-size-text-large__mobile: $font-size-text-large;

$font-size-text-small: 15px;
$font-size-text-small__mobile: $font-size-text-small;

$font-size-text-featured: 25px;
$font-size-text-featured__mobile: 17px;

$font-size-text-highlight: 17px;
$font-size-text-highlight__mobile: $font-size-text-highlight;

$font-size-text-appellation: 18px;
$font-size-text-appellation__mobile: $font-size-text-appellation;

// Heading
$font-size-heading: 36px;
$font-size-heading__mobile: $font-size-heading;
$font-size-heading-h1: 50px;
$font-size-heading-h1__mobile: 45px;
$font-size-heading-h2: 45px;
$font-size-heading-h2__mobile: 32px;
$font-size-heading-h3: 36px;
$font-size-heading-h3__mobile: 26px;
$font-size-heading-h4: 32px;
$font-size-heading-h4__mobile: 22px;
$font-size-heading-h5: 27px;
$font-size-heading-h5__mobile: 24px;
$font-size-heading-h6: 22px;
$font-size-heading-h6__mobile: 20px;

$font-size-heading-promo-h1: 77px;
$font-size-heading-promo-h1__mobile: 44px;
$font-size-heading-promo-h2: 60px;
$font-size-heading-promo-h2__mobile: 35px;
$font-size-heading-promo-h3: 53px;
$font-size-heading-promo-h3__mobile: 32px;
$font-size-heading-promo-h4: 40px;
$font-size-heading-promo-h4__mobile: 28px;
$font-size-heading-promo-h5: 28px;
$font-size-heading-promo-h5__mobile: 24px;
$font-size-heading-promo-h6: 24px;
$font-size-heading-promo-h6__mobile: 20px;

$font-size-heading-featured: 32px;
$font-size-heading-featured__mobile: 26px;

// Button
$font-size-button: 16px;
$font-size-button__mobile: $font-size-button;

$font-size-button-featured: 17px;
$font-size-button-featured__mobile: $font-size-button-featured;

$font-size-button-underline: 16px;
$font-size-button-underline__mobile: $font-size-button__mobile;

$font-size-button-option: 15px;
$font-size-button-option__mobile: $font-size-button-option;

$font-size-button-primary: $font-size-button;
$font-size-button-primary__mobile: $font-size-button__mobile;

$font-size-button-secondary: $font-size-button-primary;
$font-size-button-secondary__mobile: $font-size-button-primary__mobile;

$font-size-button-tertiary: $font-size-button-primary;
$font-size-button-tertiary__mobile: $font-size-button-primary__mobile;

// Input
$font-size-input: $font-size-text;
$font-size-input__mobile: $font-size-input;

// Label
$font-size-label: 14px;
$font-size-label__mobile: $font-size-label;

// Icon
$font-size-icon: 20px;

//==============================================================================
// FONT WEIGHTS
//==============================================================================

$font-weight-black: 900;
$font-weight-bold: 700;
$font-weight-normal: 400;

// Text
$font-weight-text: $font-weight-normal;
$font-weight-text-featured: $font-weight-bold;
$font-weight-text-highlight: $font-weight-normal;
$font-weight-text-appellation: $font-weight-bold;

// Heading
$font-weight-heading: $font-weight-bold;
$font-weight-heading-h1: $font-weight-heading;
$font-weight-heading-h2: $font-weight-heading;
$font-weight-heading-h3: $font-weight-heading;
$font-weight-heading-h4: $font-weight-heading;
$font-weight-heading-h5: $font-weight-heading;
$font-weight-heading-h6: $font-weight-heading;

$font-weight-heading-promo: $font-weight-bold;
$font-weight-heading-promo-h1: $font-weight-black;
$font-weight-heading-promo-h2: $font-weight-heading-promo;
$font-weight-heading-promo-h3: $font-weight-heading-promo;
$font-weight-heading-promo-h4: $font-weight-heading-promo;
$font-weight-heading-promo-h5: $font-weight-heading-promo;
$font-weight-heading-promo-h6: $font-weight-heading-promo;

$font-weight-heading-featured: $font-weight-bold;

// Button
$font-weight-button: $font-weight-bold;
$font-weight-button-featured: $font-weight-bold;
$font-weight-button-underline: $font-weight-bold;
$font-weight-button-option: $font-weight-bold;
$font-weight-button-primary: $font-weight-button;
$font-weight-button-secondary: $font-weight-button-primary;
$font-weight-button-tertiary: $font-weight-button-primary;

// Input
$font-weight-input: $font-weight-normal;

// Label
$font-weight-label: $font-weight-bold;

//==============================================================================
// FONT STYLES
//==============================================================================

// Text
$font-style-text: normal;
$font-style-text-featured: normal;
$font-style-text-highlight: normal;
$font-style-text-appellation: normal;

// Heading
$font-style-heading: normal;
$font-style-heading-h1: $font-style-heading;
$font-style-heading-h2: $font-style-heading;
$font-style-heading-h3: $font-style-heading;
$font-style-heading-h4: $font-style-heading;
$font-style-heading-h5: $font-style-heading;
$font-style-heading-h6: $font-style-heading;
$font-style-heading-featured: normal;

// Button
$font-style-button: normal;
$font-style-button-featured: normal;
$font-style-button-underline: normal;
$font-style-button-option: normal;
$font-style-button-primary: $font-style-button;
$font-style-button-secondary: $font-style-button-primary;
$font-style-button-tertiary: $font-style-button-primary;

// Input
$font-style-input: normal;

// Label
$font-style-label: normal;

//==============================================================================
// LETTER SPACING
//==============================================================================

// Text
$letter-spacing-text: 0;
$letter-spacing-text-featured: 0;
$letter-spacing-text-highlight: 0;
$letter-spacing-text-appellation: 0;

// Heading
$letter-spacing-heading: 1px;
$letter-spacing-heading-h1: $letter-spacing-heading;
$letter-spacing-heading-h2: $letter-spacing-heading;
$letter-spacing-heading-h3: $letter-spacing-heading;
$letter-spacing-heading-h4: $letter-spacing-heading;
$letter-spacing-heading-h5: $letter-spacing-heading;
$letter-spacing-heading-h6: $letter-spacing-heading;
$letter-spacing-heading-featured: 1px;

// Button
$letter-spacing-button: 1px;
$letter-spacing-button-featured: 0;
$letter-spacing-button-underline: 1px;
$letter-spacing-button-option: 1px;
$letter-spacing-button-primary: $letter-spacing-button;
$letter-spacing-button-secondary: $letter-spacing-button-primary;
$letter-spacing-button-tertiary: $letter-spacing-button-primary;

// Input
$letter-spacing-input: 0;

// Label
$letter-spacing-label: 0;

//==============================================================================
// LINE HEIGHTS
//==============================================================================

// Text
$line-height-text: 1.4;
$line-height-text__mobile: $line-height-text;

$line-height-text-large: $line-height-text;
$line-height-text-large__mobile: $line-height-text-large;

$line-height-text-small: $line-height-text;
$line-height-text-small__mobile: $line-height-text-small;

$line-height-text-featured: 1.4;
$line-height-text-featured__mobile: $line-height-text-featured;

$line-height-text-highlight: 1.4;
$line-height-text-highlight__mobile: $line-height-text-highlight;

$line-height-text-appellation: 1.4;
$line-height-text-appellation__mobile: $line-height-text-appellation;

// Heading
$line-height-heading: 1.2;
$line-height-heading__mobile: $line-height-heading;

$line-height-heading-h1: $line-height-heading;
$line-height-heading-h1__mobile: $line-height-heading-h1;

$line-height-heading-h2: $line-height-heading;
$line-height-heading-h2__mobile: $line-height-heading-h2;

$line-height-heading-h3: $line-height-heading;
$line-height-heading-h3__mobile: $line-height-heading-h3;

$line-height-heading-h4: $line-height-heading;
$line-height-heading-h4__mobile: $line-height-heading-h4;

$line-height-heading-h5: $line-height-heading;
$line-height-heading-h5__mobile: $line-height-heading-h5;

$line-height-heading-h6: $line-height-heading;
$line-height-heading-h6__mobile: $line-height-heading-h6;

$line-height-heading-featured: 1.2;
$line-height-heading-featured__mobile: $line-height-heading-featured;

// Button
$line-height-button: 1.4;
$line-height-button__mobile: $line-height-button;

$line-height-button-featured: 1.2;
$line-height-button-featured__mobile: $line-height-button-featured;

$line-height-button-underline: 1.4;
$line-height-button-underline__mobile: $line-height-button;

$line-height-button-option: 1.4;
$line-height-button-option__mobile: $line-height-button-option;

$line-height-button-primary: $line-height-button;
$line-height-button-primary__mobile: $line-height-button__mobile;

$line-height-button-secondary: $line-height-button-primary;
$line-height-button-secondary__mobile: $line-height-button-primary__mobile;

$line-height-button-tertiary: $line-height-button-primary;
$line-height-button-tertiary__mobile: $line-height-button-primary__mobile;

// Input
$line-height-input: 1.4;
$line-height-input__mobile: $line-height-input;

// Label
$line-height-label: 1.4;
$line-height-label__mobile: $line-height-label;

//==============================================================================
// TEXT TRANSFORM
//==============================================================================

// Text
$text-transform-text: none;
$text-transform-text-featured: none;
$text-transform-text-highlight: none;
$text-transform-text-appellation: none;

// Heading
$text-transform-heading: uppercase;
$text-transform-heading-h1: $text-transform-heading;
$text-transform-heading-h2: $text-transform-heading;
$text-transform-heading-h3: $text-transform-heading;
$text-transform-heading-h4: $text-transform-heading;
$text-transform-heading-h5: $text-transform-heading;
$text-transform-heading-h6: $text-transform-heading;
$text-transform-heading-featured: uppercase;

// Button
$text-transform-button: uppercase;
$text-transform-button-featured: uppercase;
$text-transform-button-underline: uppercase;
$text-transform-button-option: uppercase;
$text-transform-button-primary: $text-transform-button;
$text-transform-button-secondary: $text-transform-button-primary;
$text-transform-button-tertiary: $text-transform-button-primary;

// Input
$text-transform-input: none;

// Label
$text-transform-label: none;

//==============================================================================
// TEXT DECORATION
//==============================================================================

// Link
$text-decoration-link: none;
$text-decoration-link--hover: none;

// Button
$text-decoration-button: none;
$text-decoration-button--hover: none;

$text-decoration-button-featured: none;
$text-decoration-button-featured--hover: none;

$text-decoration-button-underline: underline;
$text-decoration-button-underline--hover: underline;
$text-decoration-thickness-button-underline: 5px;
$text-underline-offset-button-underline: 3px;

$text-decoration-button-option: none;
$text-decoration-button-option--hover: none;

$text-decoration-button-primary: $text-decoration-button;
$text-decoration-button-primary--hover: $text-decoration-button--hover;

$text-decoration-button-secondary: $text-decoration-button-primary;
$text-decoration-button-secondary--hover: $text-decoration-button-primary--hover;

$text-decoration-button-tertiary: $text-decoration-button-primary;
$text-decoration-button-tertiary--hover: $text-decoration-button-primary--hover;
