.ms-address-select,
.msc-address-select {
    &__item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__input-radio {
        margin-top: 4px;
        margin-right: 0.75rem;
    }

    &__button-add {
        @extend %button-secondary;
        margin-bottom: 1rem;

        &:disabled,
        &.disabled {
            @extend %button-secondary-disabled;
        }
    }
}
