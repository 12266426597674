//==============================================================================
// LAYOUT COMMON
//==============================================================================
.container-with-header {
    // All layouts with max-width
    @each $layout in $content-layouts-max {
        &.layout__#{$layout} {
            @extend %width-container-content-max;
        }
    }

    // All layouts with full-width
    @each $layout in $content-layouts-full {
        &.layout__#{$layout} {
            @extend %width-container-content-full;
        }
    }

    // All layouts with vertical margin
    @each $layout in $content-layouts-margin-y {
        &.layout__#{$layout} {
            @extend %margin-y-container-content;
        }
    }

    // All layouts with bottom margin
    @each $layout in $content-layouts-margin-bottom {
        &.layout__#{$layout} {
            @extend %margin-bottom-container-content;
        }
    }

    // All layouts with split headers
    @each $layout in $content-layouts-header-split {
        &.layout__#{$layout} > .container-with-header__header {
            margin-bottom: $margin-bottom-container-content-header-split__mobile;

            @include media-breakpoint-up(xl) {
                display: flex;
                align-items: flex-end;
                margin-bottom: $margin-bottom-container-content-header-split;
            }

            > .container-with-header__title {
                @include media-breakpoint-up(xl) {
                    align-self: flex-start;
                    margin-bottom: 0;
                }
            }

            > .container-with-header__text {
                @include media-breakpoint-up(xl) {
                    flex: 1;
                    margin-top: $margin-top-container-content-header-split-text__desktop-up;
                    margin-bottom: $margin-bottom-container-content-header-split-text__desktop-up;
                    margin-left: $margin-left-container-content-header-split-text__desktop-up;
                }
            }
        }
    }

    // All layouts with centered headers
    @each $layout in $content-layouts-header-center {
        &.layout__#{$layout} > .container-with-header__header {
            text-align: center;
        }
    }

    // All layouts with promo headings
    @each $layout in $content-layouts-heading-promo {
        &.layout__#{$layout} > .container-with-header__slots {
            @each $heading, $font-size in $font-size-heading-content-heading-promo {
                #{$heading} {
                    @include heading-font-size(
                        nth($font-size, 1),
                        nth($font-size, 2),
                        $breakpoint-content-heading-promo
                    );
                }
            }

            @each $heading, $font-weight in $font-weight-heading-content-heading-promo {
                #{$heading} {
                    @include heading-font-weight($font-weight);
                }
            }
        }
    }

    // All layouts with custom appellations
    @each $layout in $content-layouts-text-appellation {
        &.layout__#{$layout} .ms-content-block__appellation {
            @include text-color($color-text-content-text-appellation);
            @include text-font-size(
                $font-size-text-content-text-appellation,
                $font-size-text-content-text-appellation__mobile
            );
        }
    }
}

//==============================================================================
// LAYOUTS
//==============================================================================

// Promo Blocks
.container-with-header.layout__promoblocks {
    &.sublayoutpromoblocks__two,
    &.sublayoutpromoblocks__three,
    &.sublayoutpromoblocks__four {
        > .container-with-header__slots {
            @include media-breakpoint-up(lg) {
                column-count: 2;
                column-gap: 3rem;
            }

            @include media-breakpoint-up(xl) {
                column-gap: 4rem;
            }

            > * {
                margin-bottom: 2rem;

                @include media-breakpoint-up(lg) {
                    break-inside: avoid-column;
                    page-break-inside: avoid;
                    margin-bottom: 3rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                @include media-breakpoint-up(xl) {
                    margin-bottom: 4rem;
                }
            }
        }
    }

    &.sublayoutpromoblocks__two > .container-with-header__slots > * {
        @include media-breakpoint-up(lg) {
            & {
                margin-bottom: 0;
            }
        }
    }

    &.sublayoutpromoblocks__two.columnpromoblocks__left > .container-with-header__slots > * {
        @include media-breakpoint-up(lg) {
            &:first-child {
                width: 120%;
            }

            &:nth-child(2) {
                width: 80%;
                margin-left: auto;
            }
        }
    }

    &.sublayoutpromoblocks__two.columnpromoblocks__right > .container-with-header__slots > * {
        @include media-breakpoint-up(lg) {
            &:first-child {
                width: 80%;
            }

            &:nth-child(2) {
                width: 120%;
                margin-left: -20%;
            }
        }
    }

    &.sublayoutpromoblocks__three,
    &.sublayoutpromoblocks__four {
        > .container-with-header__slots > * {
            @include media-breakpoint-up(lg) {
                &:nth-child(2),
                &:nth-child(4) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.sublayoutpromoblocks__three,
    &.sublayoutpromoblocks__four {
        &.columnpromoblocks__left > .container-with-header__slots > * {
            @include media-breakpoint-up(lg) {
                &:first-child,
                &:nth-child(2) {
                    width: 120%;
                }

                &:nth-child(3),
                &:nth-child(4) {
                    width: 80%;
                    margin-left: auto;
                }
            }
        }
    }

    &.sublayoutpromoblocks__three,
    &.sublayoutpromoblocks__four {
        &.columnpromoblocks__right > .container-with-header__slots > * {
            @include media-breakpoint-up(lg) {
                &:first-child,
                &:nth-child(2) {
                    width: 80%;
                }

                &:nth-child(3),
                &:nth-child(4) {
                    width: 120%;
                    margin-left: -20%;
                }
            }
        }
    }

    .ms-content-block__image img {
        @include image-cover;
        min-height: 270px;
        max-height: 500px;
    }
}

// Hero
.container-with-header.layout__hero > .container-with-header__slots {
    @extend %width-container-content-full__mobile-only;

    .ms-content-block {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;
        }

        @include media-breakpoint-up(xxxl) {
            margin-left: -5%;
            margin-right: -5%;
        }

        &__image {
            z-index: -1;

            @include media-breakpoint-up(md) {
                margin-left: -35%;
            }

            @include media-breakpoint-up(lg) {
                margin-left: -15%;
            }

            @include media-breakpoint-up(xl) {
                margin-left: -10%;
            }

            img {
                width: 100%;
            }
        }

        &__details {
            padding: 1.5rem;
            background-color: $color-background-neutral;

            @include media-breakpoint-down(sm) {
                width: 85%;
                max-width: 400px;
                margin-top: -30%;
            }

            @include media-breakpoint-down(xs) {
                margin-top: -40%;
            }

            @include media-breakpoint-up(md) {
                @include flex-width(60%);
                padding: 1.5rem 2rem;
            }

            @include media-breakpoint-up(lg) {
                @include flex-width(45%);
                padding: 2.5rem 3rem;
            }

            @include media-breakpoint-up(xl) {
                @include flex-width(40%);
                padding: 3rem 4rem;
            }
        }

        &__text {
            @extend %text-featured;
        }

        &__appellation,
        &__title,
        &__text {
            color: $color-text-neutral;
        }

        &__cta {
            .msc-cta__primary {
                @extend %button-primary;
                @extend %button-primary-inverse;
            }

            .msc-cta__secondary {
                @extend %button-tertiary;
            }

            > * {
                @include media-breakpoint-down(xxs) {
                    @include button-width-full;
                    margin-bottom: $margin-bottom-button-content-block__mobile-down;
                }

                @include media-breakpoint-up(xs) {
                    margin-right: $margin-right-button-content-block__tablet-up;
                }

                &:last-child {
                    @include media-breakpoint-down(xxs) {
                        margin-bottom: 0;
                    }

                    @include media-breakpoint-up(xs) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

// Headline
.container-with-header.layout__headline > .container-with-header__slots {
    .ms-video-player .msc-videoplayer__video {
        max-height: 375px;
        object-fit: cover;
    }
}

// Full
.container-with-header.layout__full > .container-with-header__slots {
    .ms-video-player .msc-videoplayer__video {
        max-height: 400px;
        object-fit: cover;
    }
}

// Promo Grid
.container-with-header.layout__promogrid > .container-with-header__slots {
    display: flex;
    flex-wrap: wrap;

    > * {
        @include flex-width($width-container-content-promogrid-column__mobile);
        margin-right: $margin-right-container-content-promogrid-column__mobile;
        margin-bottom: $margin-bottom-container-content-promogrid-column__mobile;

        &:nth-child(2n) {
            margin-right: 0;
        }

        &:last-child,
        &:nth-last-child(2):nth-child(2n-1) {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(md) {
            @include flex-width($width-container-content-promogrid-column);

            &,
            &:nth-child(2n) {
                margin-right: $margin-right-container-content-promogrid-column;
            }

            &,
            &:last-child,
            &:nth-last-child(2):nth-child(2n-1) {
                margin-bottom: $margin-bottom-container-content-promogrid-column;
            }

            &:nth-child(4n) {
                margin-right: 0;
            }

            &:last-child,
            &:nth-last-child(2):nth-child(4n-1),
            &:nth-last-child(2):nth-child(4n-2),
            &:nth-last-child(2):nth-child(4n-3),
            &:nth-last-child(3):nth-child(4n-2),
            &:nth-last-child(3):nth-child(4n-3),
            &:nth-last-child(4):nth-child(4n-3) {
                margin-bottom: 0;
            }
        }
    }

    .ms-content-block__image {
        margin-bottom: $margin-bottom-img-content-promogrid;

        img {
            width: 100%;
        }
    }
}

// Promo Banner
.container-with-header.layout__promobanner {
    background: linear-gradient(
        180deg,
        $color-background 0%,
        $color-background 20%,
        $color-background-accent 20%,
        $color-background-accent 100%
    );

    @include media-breakpoint-up(md) {
        background: linear-gradient(
            90deg,
            $color-background 0%,
            $color-background 20%,
            $color-background-accent 20%,
            $color-background-accent 100%
        );
    }

    @include media-breakpoint-up(xxl) {
        background: linear-gradient(
            90deg,
            $color-background 0%,
            $color-background 30%,
            $color-background-accent 30%,
            $color-background-accent 100%
        );
    }

    .container-with-header__header,
    .container-with-header__slots {
        @extend %width-container-content-max;
    }

    .container-with-header__header .container-with-header__title {
        @include media-breakpoint-up(md) {
            padding-top: 2rem;
        }
    }

    .container-with-header__slots {
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include media-breakpoint-up(md) {
            display: flex;
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
    }

    .ms-content-block {
        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
        }
    }

    .ms-content-block__image,
    .ms-content-block__link {
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
            @include flex-width(30%);
            margin-right: 5%;
            margin-bottom: 0;
        }
    }

    .ms-content-block__details {
        @include media-breakpoint-up(md) {
            @include flex-width(60%);
        }
    }

    .ms-content-block__image img {
        @include media-breakpoint-up(md) {
            width: 100%;
        }
    }
}

// Icon Grid
.container-with-header.layout__icongrid > .container-with-header__slots {
    @include center-flex(x);
    flex-wrap: wrap;

    > * {
        width: 50%;
        max-width: 250px;
        padding: 1rem;

        @include media-breakpoint-up(md) {
            width: auto;
            min-width: 160px;
            max-width: 200px;
            padding: 1rem 2rem;
        }

        @include media-breakpoint-up(xl) {
            min-width: 220px;
            max-width: 370px;
            padding: 1rem 3rem;
        }

        @include media-breakpoint-up(xxl) {
            min-width: 250px;
            max-width: 400px;
            padding: 1rem 4rem;
        }
    }

    .ms-content-block__image {
        height: 85px;
        margin-bottom: 1rem;
    }

    .ms-content-block__details {
        @extend %text-highlight;
        font-weight: $font-weight-bold;
        text-transform: uppercase;

        @include media-breakpoint-up(xl) {
            font-size: 24px;
        }
    }
}

// Text Grid
.container-with-header.layout__textgrid > .container-with-header__slots {
    @include center-flex(x);
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
        width: 50%;
        margin: auto;
    }

    > * {
        width: 50%;
        padding: 1rem;

        @include media-breakpoint-up(xxl) {
            width: 25%;
        }
    }

    .ms-content-block__image {
        display: none;
    }

    .ms-content-block__title {
        @extend %heading-featured;
        text-align: center;
    }
}

// Logo Grid
.container-with-header.layout__logogrid {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: $color-background-accent;

    @include media-breakpoint-up(xl) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    > .container-with-header__slots {
        @extend %width-container-content-max;
        @include center-flex;
        flex-wrap: wrap;

        > * {
            padding: 1rem;

            @include media-breakpoint-up(md) {
                padding: 1rem 2rem;
            }

            @include media-breakpoint-up(xxl) {
                padding: 1rem 3rem;
            }

            img {
                max-width: 90px;

                @include media-breakpoint-up(sm) {
                    max-width: 200px;
                }
            }
        }
    }
}

// Page
.container-with-header.layout__page {
    > .container-with-header__slots > * {
        @include media-breakpoint-down(xl) {
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.sublayoutpage__full > .container-with-header__slots {
        margin-top: 2rem;

        > * {
            margin-bottom: 2rem;
    
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.sublayoutpage__leftsidebar,
    &.sublayoutpage__twocolumns,
    &.sublayoutpage__threecolumns {
        > .container-with-header__slots {
            @include media-breakpoint-up(xl) {
                display: flex;
                flex-wrap: wrap;
            }

            > .container-with-header > .container-with-header__slots > * {
                margin-bottom: 2rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.sublayoutpage__leftsidebar > .container-with-header__slots > * {
        &:first-child {
            @include media-breakpoint-up(xl) {
                @include flex-width(10%);
                margin-right: 5%;
            }

            .list-of-links__link {
                @include media-breakpoint-up(xl) {
                    display: block;
                    margin-right: 0;
                }
        
                @include media-breakpoint-down(xl) {
                    text-decoration: underline;
                    text-underline-position: under;
                }
            }
        }

        &:nth-child(2) {
            @include media-breakpoint-up(xl) {
                flex: 1;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &.sublayoutpage__twocolumns > .container-with-header__slots > * {
        @include media-breakpoint-up(xl) {
            @include flex-width(47%);
            margin-right: 6%;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &.sublayoutpage__threecolumns > .container-with-header__slots > * {
        @include media-breakpoint-up(xl) {
            @include flex-width(30%);
            margin-right: 5%;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

// Brands
.container-with-header.layout__brands > .container-with-header__slots {
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
        display: flex;
        margin-top: 5rem;
    }

    > .container-with-header:first-child {
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
            @include flex-width(15%);
            min-width: 200px;
            margin-right: 5%;
            margin-bottom: 0;
        }

        .container-with-header__title {
            margin-bottom: 1rem;
        }

        .list-of-links__link {
            @include media-breakpoint-up(md) {
                display: block;
                margin-right: 0;
            }

            @include media-breakpoint-down(sm) {
                text-decoration: underline;
                text-underline-position: under;
            }
        }
    }

    > .container-with-header:nth-child(2) {
        @include media-breakpoint-up(md) {
            flex: 1;
        }

        > .container-with-header__slots {
            display: flex;
            flex-wrap: wrap;
            margin: -0.5rem;

            > * {
                @include flex-width(100%/2);
                display: flex;
                padding: 0.5rem;

                @include media-breakpoint-up(md) {
                    @include flex-width(100%/3);
                }

                @include media-breakpoint-up(lg) {
                    @include flex-width(100%/4);
                }

                @include media-breakpoint-up(xl) {
                    @include flex-width(100%/5);
                }

                > * {
                    @include center-flex;
                    width: 100%;
                    min-height: 180px;
                    padding: 2rem;
                    background-color: $color-background-accent;
                }
            }
        }

        // Appellation is rendering even when empty.
        // Remove once fixed.
        .ms-content-block__details {
            display: none;
        }

        img {
            object-fit: contain;
        }
    }
}
